import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  makeStateKey,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TransferState
} from '@angular/core';
import {isPlatformBrowser, isPlatformServer, NgForOf, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {Tasma, TasmaDataService} from '../../services/tasma-data/tasma-data.service';
import {Textile, TextileDataService} from '../../services/textile-data/textile-data.service';
import {Etykieta, LabelDataService} from '../../services/label-data/label-data.service';
import {Drukarka, PrinterDataService} from '../../services/printer-data/printer-data.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TapeCardComponent} from '../../components/tape-card/tape-card.component';
import {PrinterCardComponent} from '../../components/printer-card/printer-card.component';
import {LabelCardComponent} from '../../components/label-card/label-card.component';
import {TextileCardComponent} from '../../components/textile-card/textile-card.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {Aplikator, AplikatoryDataService} from '../../services/aplikatory-data/aplikatory-data.service';
import {Nawijak, NawijakiDataService} from '../../services/nawijaki-data/nawijaki-data.service';
import {AplikatorCardComponent} from '../../components/aplikator-card/aplikator-card.component';


const STATE_KEY = makeStateKey<boolean>('subscribing');

@Component({
  selector: 'app-product-list',
  imports: [
    NgForOf,
    NgIf,
    TapeCardComponent,
    PrinterCardComponent,
    LabelCardComponent,
    TextileCardComponent,
    AplikatorCardComponent
  ],
  templateUrl: './product-list.component.html',
  standalone: true,
  styleUrl: './product-list.component.css',
  // animations: [
  //   trigger('fadeAnimation', [
  //     transition('* => *', [ // Każda zmiana stanu wyzwala animację
  //       style({ opacity: 0 }),
  //       animate('300ms ease-in', style({ opacity: 1 }))
  //     ])
  //   ])
  // ]
})
export class ProductListComponent implements OnInit, OnDestroy{
  active_category = 0;
  filteredTasmy: Tasma[] = [];
  filteredTekstylia: Textile[] = [];
  filteredEtykiety: Etykieta[] = [];
  filteredDrukarki: Drukarka[] = [];
  filteredAplikatory: Aplikator[] = [];
  filteredNawijaki: Nawijak[] = [];
  slug: string | null = '';
  private subscription!: Subscription;
  private routeSub!: Subscription;
  subscribing = true;
  shouldAnimate = true;


  constructor(private route: ActivatedRoute,
              private transferState: TransferState,
              @Inject(PLATFORM_ID) private platformID: Object,
              private tasmaService: TasmaDataService, private textileService: TextileDataService,
              private labelService: LabelDataService, private printerService: PrinterDataService,
              private aplikatoryService: AplikatoryDataService, private nawijakiService: NawijakiDataService) {}

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.startSubscribing();
    });
  }

  startSubscribing(){
    if (this.route.snapshot.routeConfig && 'path' in this.route.snapshot.routeConfig) {
      this.slug = this.route.snapshot.routeConfig.path || null;
    }
    if (this.slug == 'tasmy-termotransferowe') {
      this.active_category = 0;
      this.subscription = this.tasmaService.filteredItems$.subscribe(items => {
        this.filteredTasmy = items;
      });
    } else if (this.slug == 'tekstylia') {
      this.active_category = 1;
      this.subscription = this.textileService.filteredItems$.subscribe(items => {
        this.filteredTekstylia = items;
      });
    } else if (this.slug == 'etykiety') {
      this.active_category = 2;
      this.subscription = this.labelService.filteredItems$.subscribe(items => {
        this.filteredEtykiety = items;
      });
    } else if (this.slug == 'drukarki') {
      this.active_category = 3;
      this.subscription = this.printerService.filteredItems$.subscribe(items => {
        this.filteredDrukarki = items;
      });
    } else if (this.slug == 'aplikatory') {
      this.active_category = 4;
      // this.subscription = this.aplikatoryService.filteredItems$.subscribe(items => {
      //   this.filteredDrukarki = items;
      // });
      this.filteredAplikatory = Array.from(this.aplikatoryService.aplikatoryCache.values());
    } else if (this.slug == 'nawijaki') {
      this.active_category = 5;
      // this.subscription = this.printerService.filteredItems$.subscribe(items => {
      //   this.filteredDrukarki = items;
      // });
      this.filteredNawijaki = Array.from(this.nawijakiService.nawijakiCache.values());
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
