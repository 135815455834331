import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";

export interface FeatureSetItem<TFeature> {
  key: TFeature;
  value: boolean;
  disabled: boolean;
}

export interface FeatureConfig<TProduct, TFeature> {
  name: string;
  displayName: string;
  description: string;
  featureSetArray: FeatureSetItem<TFeature>[];
  getFeatureFromProduct: (product: TProduct) => TFeature | TFeature[];
  isNumber: boolean;
  isArray: boolean;
}

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  styleUrls: ['./product-filter.component.css']
})
export class ProductFilterComponent<TProduct> implements OnInit {
  @Input() allProducts: TProduct[] = [];
  @Input() filteredProducts: TProduct[] = [];

  @Input() featureConfigs: FeatureConfig<TProduct, any>[] = [];

  @Input() isCategoryExpanded: { [key: string]: boolean } = {};

  @Output() filteredProductsChange = new EventEmitter<TProduct[]>();

  @Output() categoryStateChange = new EventEmitter<{ [key: string]: boolean }>();


  ngOnInit() {
    this.filteredProducts = [...this.allProducts];
    // Inicjalizacja stanów rozwinięcia kategorii
    // this.featureConfigs.forEach(config => {
    //   this.isCategoryExpanded[config.name] = false;
    // });
    this.initializeFeatures();
  }

  private initializeFeatures() {
    this.updateAvailableFeatures();
  }

  toggleFeature<TFeature>(feature: TFeature, config: FeatureConfig<TProduct, TFeature>) {
    const featureSetArray = config.featureSetArray;
    const featureIndex = featureSetArray.findIndex(f => f.key === feature);
    if (featureIndex !== -1) {
      if (featureSetArray[featureIndex].value || !featureSetArray[featureIndex].disabled) {
        featureSetArray[featureIndex] = {
          key: feature,
          value: !featureSetArray[featureIndex].value,
          disabled: featureSetArray[featureIndex].disabled
        };
        this.updateFilteredProducts();
      }
    }
  }

  private updateFilteredProducts() {
    this.filteredProducts = this.allProducts.filter(product =>
      this.featureConfigs.every(config =>
        this.matchesSomeSelectedFeatures(product, config)
      )
    );
    this.filteredProductsChange.emit(this.filteredProducts); // Emitujemy nowe wyniki
    this.updateAvailableFeatures();
  }

  private matchesSomeSelectedFeatures<TFeature>(product: TProduct, config: FeatureConfig<TProduct, TFeature>): boolean {
    const selectedFeatures = config.featureSetArray.filter(f => f.value);
    if (selectedFeatures.length === 0) {
      return true;
    }

    const productFeatureValue = config.getFeatureFromProduct(product);

    if (!productFeatureValue) {
      // Produkt nie posiada danej cechy
      return false;
    }

    if (config.isArray && Array.isArray(productFeatureValue)) {
      if (config.isNumber){
        return selectedFeatures.some(feature =>
          productFeatureValue.some((item: any) => item === feature.key)
        );
      } else {
      return selectedFeatures.some(feature =>
        // @ts-ignore
        productFeatureValue.some((item: any) => item.id === feature.key.id)
      );
      }
    } else {
      return selectedFeatures.some(feature =>
        productFeatureValue === feature.key
      );
    }
  }

  private getProductSubList(excludeFeatureName: string): TProduct[] {
    return this.allProducts.filter(product =>
      this.featureConfigs.every(config =>
        config.name === excludeFeatureName || this.matchesSomeSelectedFeatures(product, config)
      )
    );
  }

  private updateAvailableFeatures() {
    this.featureConfigs.forEach(config => {
      const productSubList = this.getProductSubList(config.name);
      this.updateFeatureAvailability(config, productSubList);
    });
  }

  private updateFeatureAvailability<TFeature>(config: FeatureConfig<TProduct, TFeature>, products: TProduct[]) {
    config.featureSetArray.forEach((featureKV, index) => {
      const isAvailable = products.some(product => {
        const productFeatureValue = config.getFeatureFromProduct(product);
        if (config.isArray && Array.isArray(productFeatureValue)) {
          if (config.isNumber){
            return productFeatureValue.some((item: any) => {
              if (item !== undefined) {
                return item === featureKV.key;
              } else {
                return false;
              }
            });
          } else {
            return productFeatureValue.some((item: any) => {
              if (item !== undefined) {
              // @ts-ignore
                return item.id === featureKV.key.id;
              } else {
                return false;
              }
            });
          }
        } else {
          return productFeatureValue === featureKV.key;
        }
      });
      config.featureSetArray[index] = { key: featureKV.key, value: featureKV.value, disabled: !isAvailable };
    });
  }

  clearFilters() {
    this.featureConfigs.forEach(config => {
      config.featureSetArray.forEach(feature => {
        feature.value = false;
        feature.disabled = false;
      });
      this.isCategoryExpanded[config.name] = false;
    });
    this.updateFilteredProducts();
  }

  async collectFilterEvent(category: string, option: string, toggle: boolean): Promise<void> {
    // @ts-ignore
    if (window['saitag'] && window['saitag'].collectFilterEvent) {
      // @ts-ignore
      window['saitag'].collectFilterEvent('Tasmy filtry', category, option, toggle);
    }
  }
}
