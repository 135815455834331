import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HostAddressService {

  constructor() { }
  getHostAddress(): string {
    return 'https://h42.sai.pl/api/';
    // return 'https://sai.pl/api/';
    // return 'http://localhost:3000/';
    // return 'http://127.0.0.1:3000/';
    // return 'http://192.168.1.5:3000/';
  }
}
