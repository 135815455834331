import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';

@Component({
  selector: 'app-enquiry-window',
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './enquiry-window.component.html',
  standalone: true,
  styleUrl: './enquiry-window.component.css'
})
export class EnquiryWindowComponent {
  @Output() close = new EventEmitter<boolean>();
  form: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      imie: [''],
      firma: [''],
      mail: [''],
      tel: [''],
      wiadomosc: [''],
      consent: [false, Validators.requiredTrue],
    });
  }
  async   onSubmit() {
    this.submitted = true; // Mark form as submitted

    if (this.form.valid) {
      try {
        this.form.reset();
        this.submitted = false; // Mark form as submitted

        // Show a toast notification
        // this.toastr.success('Form has been sent!', 'Success');
      } catch (error) {
        console.error('Error saving form data:', error);
      }
    } else {
      // console.error('Form is invalid');
      // this.toastr.error('Please accept our privacy policy', 'Lack of consent');
    }
  }
}
