import {Component, Input} from '@angular/core';
import {Tasma} from '../../services/tasma-data/tasma-data.service';
import {ColorDrawerComponent} from '../color-drawer/color-drawer.component';
import {NgForOf, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-tape-card',
  imports: [
    ColorDrawerComponent,
    NgForOf,
    NgOptimizedImage,
    RouterLink
  ],
  templateUrl: './tape-card.component.html',
  standalone: true,
  styleUrl: './tape-card.component.css'
})
export class TapeCardComponent {
  @Input() produkt: Tasma = {
    id: 0,
    nazwa: '',
    kolory: [],
    glowice: [],
    typ: {
      id: 0,
      nazwa: ''
    },
    szybkosc_zadruku: 0,
    odpornosc_temperaturowa: 0,
    odpornosc_mechaniczna: 0,
    odpornosc_chemiczna: 0,
    czulosc: 0,
    opis: '',
    zastosowania: [],
    materialy: [],
    img: '',
    display_type: 0,
    display_spec: '',
    slug: '',
    meta: ''
  }
  concatenateNazwa(objects: { nazwa: string }[]): string {
    return objects.map(obj => obj.nazwa).join(', ');
  }

  protected readonly console = console;
}
