<div class="window">
  <div class="container">
    <div class="close-button" (click)="close.emit(true)">
      <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <path d="M29.4238 27.8329C29.6352 28.0442 29.7539 28.3309 29.7539 28.6298C29.7539 28.9286 29.6352 29.2153 29.4238 29.4266C29.2125 29.638 28.9258 29.7567 28.627 29.7567C28.3281 29.7567 28.0414 29.638 27.8301 29.4266L21.8779 23.4726L15.9238 29.4248C15.7125 29.6361 15.4258 29.7548 15.127 29.7548C14.8281 29.7548 14.5414 29.6361 14.3301 29.4248C14.1187 29.2134 14 28.9268 14 28.6279C14 28.329 14.1187 28.0424 14.3301 27.831L20.2841 21.8788L14.332 15.9248C14.1206 15.7134 14.0019 15.4268 14.0019 15.1279C14.0019 14.829 14.1206 14.5424 14.332 14.331C14.5433 14.1197 14.8299 14.0009 15.1288 14.0009C15.4277 14.0009 15.7144 14.1197 15.9257 14.331L21.8779 20.2851L27.832 14.3301C28.0433 14.1187 28.3299 14 28.6288 14C28.9277 14 29.2144 14.1187 29.4257 14.3301C29.637 14.5414 29.7558 14.8281 29.7558 15.127C29.7558 15.4258 29.637 15.7125 29.4257 15.9238L23.4716 21.8788L29.4238 27.8329Z" fill="white"/>
      </svg>
    </div>
    <div class="main-frame">
      <div class="ContactInfo">
        <div class="frame1">
          <div class="frame">
            <div class="text1">Skontaktuj się z ekspertem</div>
            <div class="text2">Wypełnij formularz lub skontaktuj się z nami bezpośrednio, a nasz zespół specjalistów pomoże znaleźć optymalne rozwiązanie dopasowane do Twoich potrzeb.</div>
          </div>
        </div>
        <div class="frame2">
          <a class="contact-anchor" href="mailto:kontakt@sai.com.pl">kontakt&#64;sai.com.pl</a>
          <a class="contact-anchor" href="tel:+48 531 060 571">+48 531 060 571</a>
        </div>
      </div>
      <div class="AccordionLine"></div>
      <form [formGroup]="form">
        <div class="ContactDetails">
          <div class="text-form-container">
            <div class="title-frame">
            <div class="title">Imię i Nazwisko</div>
            </div>
            <input formControlName="imie" class="form-frame"/>
          </div>
          <div class="text-form-container">
            <div class="title-frame">
              <div class="title">Firma</div>
            </div>
            <input formControlName="firma" class="form-frame"/>
          </div>
          <div class="text-form-container">
            <div class="title-frame">
              <div class="title">Adres e-mail</div>
            </div>
            <input formControlName="mail" class="form-frame"/>
          </div>
          <div class="text-form-container">
            <div class="title-frame">
              <div class="title">
                Numer telefonu
              </div>
              <div class="sub-frame">
                opcjonalne
              </div>
            </div>
            <input formControlName="tel" class="form-frame"/>
          </div>
          <div class="text-form-container">
            <div class="title-frame">
              <div class="title">Wiadomość</div>
            </div>
            <input formControlName="wiadomosc" class="form-frame tall"/>
          </div>
          <div class="zgoda">
            <input formControlName="consent" type="checkbox" class="rectangle"
                   [class.error]="submitted && form.get('consent')?.invalid"/>
            <div class="text">Zgadzam się na przetwarzanie moich danych osobowych w celach informacyjnych i marketingowych, opisanych w Polityce Prywatności. Zobacz: Politykę Prywatności oraz Regulamin.</div>
          </div>
          <div class="ButtonFlat Dark" (click)="onSubmit()">
            Wyślij zapytanie
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
