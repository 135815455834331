import {Component, Input} from '@angular/core';
import {Drukarka} from '../../services/printer-data/printer-data.service';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-printer-card',
    imports: [
        NgOptimizedImage
    ],
  templateUrl: './printer-card.component.html',
  standalone: true,
  styleUrl: './printer-card.component.css'
})
export class PrinterCardComponent {
  @Input() produkt: Drukarka = {
    id: 0,
    model: {
      id: 0,
      nazwa: ''
    },
    producent: {
      id: 0,
      nazwa: ''
    },
    numer_produktu: '',
    prowadzenie: {
      id: 0,
      nazwa: ''
    },
    metody_wydruku: [],
    rodzaj_glowicy: {
      id: 0,
      nazwa: ''
    },
    szerokosc_glowicy: 0,
    rozdzielczosc_glowicy: 0,
    maks_predkosc_druku: 0,
    maks_szerokosc_wydruku: 0,
    min_dlugosc_wydruku: 0,
    maks_dlugosc_wydruku: 0,
    szerokosc_drukarki: 0,
    glebokosc_drukarki: 0,
    wysokosc_drukarki: 0,
    waga_drukarki: 0,
    ekran: {
      id: 0,
      nazwa: ''
    },
    interfejsy: [],
    interfejsy_dodatkowe: [],
    interfejsy_all: [],
    wlasciwosci: [],
    wlasciwosci_dodatkowe: [],
    wlasciwosci_all: [],
    min_szerokosc_materialu: 0,
    maks_szerokosc_materialu: 0,
    maks_srednica_rolki_materialu: 0,
    srednice_gilz_materialu: [],
    min_szerokosc_tasmy: 0,
    maks_szerokosc_tasmy: 0,
    maks_srednica_rolki_tasmy: 0,
    maks_dlugosc_nawoju_tasmy: 0,
    typy_nawoju: [],
    srednice_gilz_tasmy: [],
    oprogramowanie: [],
    sterowniki: [],
    slug: '',
    opis: '',
    meta: ''
  }
  concatenateNazwa(objects: { nazwa: string }[]): string {
    return objects.map(obj => obj.nazwa).join(', ');
  }

  protected readonly encodeURIComponent = encodeURIComponent;
}
