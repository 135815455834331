<div *ngFor="let config of featureConfigs" class="filter_category">
  <div class="filter_category_title" (click)="isCategoryExpanded[config.name] = !isCategoryExpanded[config.name]; categoryStateChange.emit(isCategoryExpanded)">
<!--    <span [class.rotated]="isCategoryExpanded[config.name]">▼</span>{{ config.displayName }}</div>-->
    <div class="filter_category_name">{{config.displayName}}</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 15 8" fill="none" class="arrow" [class.rotated]="isCategoryExpanded[config.name]">
      <path d="M0.52958 6.86142L7.1955 0.195502C7.25741 0.133524 7.33093 0.0843573 7.41185 0.0508113C7.49277 0.0172653 7.57952 0 7.66712 0C7.75472 0 7.84146 0.0172653 7.92238 0.0508113C8.0033 0.0843573 8.07682 0.133524 8.13873 0.195502L14.8047 6.86142C14.9297 6.9865 15 7.15615 15 7.33304C15 7.50993 14.9297 7.67957 14.8047 7.80465C14.6796 7.92973 14.5099 8 14.333 8C14.1561 8 13.9865 7.92973 13.8614 7.80465L7.66712 1.60951L1.47281 7.80465C1.41088 7.86658 1.33735 7.91571 1.25643 7.94923C1.17551 7.98275 1.08878 8 1.00119 8C0.913608 8 0.826879 7.98275 0.745958 7.94923C0.665039 7.91571 0.591514 7.86658 0.52958 7.80465C0.467647 7.74272 0.418519 7.66919 0.385001 7.58827C0.351483 7.50735 0.33423 7.42062 0.33423 7.33304C0.33423 7.24545 0.351483 7.15872 0.385001 7.0778C0.418519 6.99688 0.467647 6.92336 0.52958 6.86142Z" fill="black"/>
    </svg>
  </div>
  <div *ngIf="isCategoryExpanded[config.name]" class="filter_category_items">
<!--    <div class="category_description">-->
<!--      {{ config.description }}-->
<!--    </div>-->
    <div *ngFor="let feature of config.featureSetArray" class="filter-option">
      <label class="feature">
        <input class="feature_checkbox" type="checkbox"
               [checked]="feature.value"
               (click)="$event.preventDefault(); toggleFeature(feature.key, config); collectFilterEvent(config.name, feature.key.nazwa || feature.key.toString(), !feature.value)"
               [class.disabled]="feature.disabled"/>
        <div class="feature_text" [class.disabled]="feature.disabled">
          {{ feature.key.nazwa || feature.key.pantone || feature.key }}
        </div>
      </label>
    </div>
  </div>
</div>
