import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {Aplikator} from '../aplikatory-data/aplikatory-data.service';


export interface Nawijak {
  id: number;
  opis: string;
  nazwa: string;
  maks_predkosc: number;
  maks_szerokosc: number;
  maks_srednica_rolki: number;
  min_srednica_rdzenia: number;
  maks_srednica_rdzenia: number;
  tryb_pracy: string;
  kierunek_obrotow: string[];
  zasilanie: number[];
  szer: number;
  glebokosc: number;
  wys: number;
  waga: number;
  imgs: string[];
  meta: string;
  slug: string;
}
@Injectable({
  providedIn: 'root'
})
export class NawijakiDataService {
  get nawijakiCache(): Map<number, Nawijak> {
    return this._nawijakiCache;
  }
  private _nawijakiCache: Map<number, Nawijak> = new Map();
  constructor() {
    let nawijak: Nawijak = {
      id: 0,
      nazwa: 'NS-101',
      opis: 'Przystosowany do pracy z większością dostępnych na rynku drukarek etykiet',
      maks_predkosc: 12,
      maks_szerokosc: 120,
      maks_srednica_rolki: 220,
      min_srednica_rdzenia: 25,
      maks_srednica_rdzenia: 101,
      tryb_pracy: 'płynna regulacja predkości obrotowej/naprężenia wstęgi',
      kierunek_obrotow: ['w prawo', 'pozycja neutralnna', 'w lewo'],
      zasilanie: [100, 220],
      szer: 220,
      glebokosc: 325,
      wys: 255,
      waga: 4.5,
      imgs: [],
      meta: '',
      slug: 'NS-101'
    }
    this._nawijakiCache.set(0, nawijak);
  }

  fetchNawijakiBySlug(slug: string): Observable<Nawijak[]>{
    for (let nawijak of this._nawijakiCache.values()) {
      if (nawijak.slug === slug) {
        return of([nawijak]);
      }
    }
    return of();
  }
}
