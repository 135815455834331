import { Injectable } from '@angular/core';
import {BaseCecha, ExtendedCecha} from "../tasma-data/tasma-data.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {HttpParams} from '@angular/common/http';
import {Drukarka} from '../printer-data/printer-data.service';

export interface SubAplikator{
  nazwa: string;
  szerokosc_etykiety: number[];
  dlugosc_etykiety: number[];
  maks_srednica_rolki: number;
  min_przerwa_etykiet: number;
  min_grubosc: number;
  imgs: string[];
}

export interface Aplikator{
  id: number,
  slug: string;
  meta: string;
  opis: string;
  details: SubAplikator[];
}

@Injectable({
  providedIn: 'root'
})
export class AplikatoryDataService {
  get aplikatoryCache(): Map<number, Aplikator> {
    return this._aplikatoryCache;
  }

  private _aplikatoryCache: Map<number, Aplikator> = new Map();
  constructor() {
    let aplikator: Aplikator = {
      id: 0,
      slug: 'APN',
      meta: '',
      opis: 'Aplikator przeznaczony do naklejania etykiet o nieregularnych kształtach',
      details: [
        {
          nazwa: 'APN-30',
          szerokosc_etykiety: [20, 30],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APN-30.png']
        },
        {
          nazwa: 'APN-60',
          szerokosc_etykiety: [20, 60],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APN-60.png']
        },
        {
          nazwa: 'APN-100',
          szerokosc_etykiety: [20, 100],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APN-100.png']
        }
      ]
    };
    this._aplikatoryCache.set(0, aplikator);

    aplikator = {
      id: 1,
      slug: 'APF',
      meta: '',
      opis: 'Aplikator przeznaczony do naklejania etykiet o nieregularnych kształtach',
      details: [
        {
          nazwa: 'APF-30',
          szerokosc_etykiety: [20, 30],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APF-30.png']
        },
        {
          nazwa: 'APF-60',
          szerokosc_etykiety: [20, 60],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APF-60.png']
        },
        {
          nazwa: 'APF-100',
          szerokosc_etykiety: [20, 100],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APF-100.png']
        }
      ]
    };

    this._aplikatoryCache.set(1, aplikator);

    aplikator = {
      id: 2,
      slug: 'APN10',
      meta: '',
      opis: 'Aplikator przeznaczony do naklejania etykiet o nieregularnych kształtach',
      details: [
        {
          nazwa: 'APN10-30',
          szerokosc_etykiety: [20, 30],
          dlugosc_etykiety: [18, 60],
          maks_srednica_rolki: 100,
          min_przerwa_etykiet: 3,
          min_grubosc: 50,
          imgs: ['aplikatory/APLIKATOR_TOWA_APN-10-30.png']
        }
      ]
    };
    this._aplikatoryCache.set(2, aplikator);
  }

  fetchAplikatoryBySlug(slug: string): Observable<Aplikator[]>{
    for (let aplikator of this._aplikatoryCache.values()) {
      if (aplikator.slug === slug) {
        return of([aplikator]);
      }
    }
    return of();
  }
}
