import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet} from '@angular/router';
import {Tasma, TasmaDataService} from '../../services/tasma-data/tasma-data.service';
import {Textile, TextileDataService} from '../../services/textile-data/textile-data.service';
import {Etykieta, LabelDataService} from '../../services/label-data/label-data.service';
import {Drukarka, PrinterDataService} from '../../services/printer-data/printer-data.service';
import {FeatureConfig, ProductFilterComponent} from '../../components/product-filter/product-filter.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {isPlatformBrowser, NgIf} from '@angular/common';
import {filter, Subscription} from 'rxjs';
import {Aplikator, AplikatoryDataService} from '../../services/aplikatory-data/aplikatory-data.service';
import {Nawijak, NawijakiDataService} from '../../services/nawijaki-data/nawijaki-data.service';

@Component({
  selector: 'app-products-page',
  imports: [
    ProductFilterComponent,
    RouterOutlet,
    NgIf,
    RouterLink
  ],
  templateUrl: './products-page.component.html',
  standalone: true,
  styleUrl: './products-page.component.css',
  animations: [
    trigger('fadeAnimation', [
      state('true', style({opacity: 1})),
      state('false', style({opacity: 1})),
      transition('false => true', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition('true => false', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class ProductsPageComponent implements OnInit, AfterViewInit, OnDestroy{

  product_categories = [
    {id: 0, sid: '01', category: 'Taśmy termotransferowe', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/tasmy-termotransferowe.webp'},
    {id: 1, sid: '02', category: 'Taśmy tekstylne', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/tekstylia.webp'},
    {id: 2, sid: '03', category: 'Etykiety', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/etykiety.webp'},
    {id: 3, sid: '04', category: 'Drukarki', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/drukarki.webp'},
    {id: 4, sid: '05', category: 'Aplikatory', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/drukarki.webp'},
    {id: 5, sid: '06', category: 'Nawijaki', desc: 'Poznaj naszą ofertę wysokiej jakości taśm tekstylnych – nylonowych, satynowych, poliuretanowych i bawełnianych.', img: 'assets/drukarki.webp'}

  ];
  active_layout = 0;
  active_category = 0;
  number = 0;
  filtryOpened = false;

  tasmyFeatureConfigs: FeatureConfig<Tasma, any>[] = [
    {
      name: 'kolory',
      displayName: 'Kolory',
      description: 'Dostępne kolory zadruku.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.kolory,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'glowice',
      displayName: 'Rodzaj głowicy',
      description: 'Głowica drukarki w której ma być użyta taśma.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.glowice,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'materialy',
      displayName: 'Zadrukowywane materiały',
      description: 'Materiały, które mogą być zadrukowane daną taśmą.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.materialy,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'zastosowania',
      displayName: 'Zastosowania',
      description: 'Zastosowania, w których dana taśma bardzo dobrze się sprawdza.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.zastosowania,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'czulosci',
      displayName: 'Czułość',
      description: 'Czułość taśmy termotransferowej wpływa na względną ilość energii potrzebnej do pomyślnego zadrukowania danego materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.czulosc,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'odpornosciMechaniczne',
      displayName: 'Odporność mechaniczna',
      description: 'Względna odporność gotowego zadruku na ścieranie, podana w skali od 1 do 10.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.odpornosc_mechaniczna,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'odpornosciChemiczne',
      displayName: 'Odporność chemiczna',
      description: 'Względna odporność na substancje chemiczne podana w skali od 1 do 10.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.odpornosc_chemiczna,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'szybkosci',
      displayName: 'Szybkość zadruku',
      description: 'Maksymalna szybkość zadruku dla której nadruk pozostaje dobrej jakości w mm/s.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.szybkosc_zadruku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'odpornosciTemperaturowe',
      displayName: 'Odporność temperaturowa',
      description: 'Maksymalna temperatura, w której wydruk nie zmienia swoich właściwości, podana w stopniach Celsjusza.',
      featureSetArray: [],
      getFeatureFromProduct: (tasma: Tasma) => tasma.odpornosc_temperaturowa,
      isNumber: false,
      isArray: false,
    },
  ];

  textileFeatureConfigs: FeatureConfig<Textile, any>[] = [
    {
      name: 'kolory',
      displayName: 'Kolory',
      description: 'Dostępne kolory materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.kolory,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'rodzaj_materialu',
      displayName: 'Rodzaj materiału',
      description: 'Rodzaj materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.rodzaj_materialu,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'struktura',
      displayName: 'Struktura',
      description: 'Struktura materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.struktura,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'grubosc',
      displayName: 'Grubość',
      description: 'Grubość materiału podana w μm.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.grubosc,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'gramatura',
      displayName: 'Gramatura',
      description: 'Gramatura materiału podana w g/m².',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.gramatura,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksymalna_predkosc_zadruku',
      displayName: 'Maksymalna prędkość zadruku',
      description: 'Maksymalna prędkość zadruku podana w mm/s.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.maksymalna_predkosc_zadruku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'sztywnosc',
      displayName: 'Sztywność',
      description: 'Względna sztywność materiału w skali od 1 do 6.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.sztywnosc,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'metody_zadruku',
      displayName: 'Metody zadruku',
      description: 'Metody zadruku dostępne dla materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.metody_zadruku,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'zadruk',
      displayName: 'Zadruk',
      description: 'Możliwość zadruku materiału po jednej lub obu stronach.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.zadruk,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'pranie',
      displayName: 'Pranie',
      description: 'Odporność materiału na dany rodzaj prania.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.pranie,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'przeznaczenia',
      displayName: 'Przeznaczenia',
      description: 'Przeznaczenia materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.przeznaczenia,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'zastosowania',
      displayName: 'Zastosowania',
      description: 'Zastosowania materiału.',
      featureSetArray: [],
      getFeatureFromProduct: (textile: Textile) => textile.zastosowania,
      isNumber: false,
      isArray: true,
    },
  ];

  labelFeatureConfigs: FeatureConfig<Etykieta, any>[] = [
    {
      name: 'materialyWierzchnie',
      displayName: 'Materiał wierzchni',
      description: 'Rodzaj materiału wierzchniego.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.material_wierzchni,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'druki',
      displayName: 'Rodzaj druku',
      description: 'Rodzaj druku dla etykiety.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.druk,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'powierzchnie',
      displayName: 'Wykończenie powierzchni',
      description: 'Rodzaj wykończenia powierzchni.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.powierzchnia,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'kleje',
      displayName: 'Klej',
      description: 'Rodzaj zastosowanego kleju.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.klej,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'kolory',
      displayName: 'Kolory',
      description: 'Dostępne kolory etykiety.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.kolory,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'przeznaczenia',
      displayName: 'Przeznaczenia',
      description: 'Możliwe przeznaczenia etykiety.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.przeznaczenia,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'wlasciwosci',
      displayName: 'Właściwości',
      description: 'Charakterystyczne właściwości etykiety.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.wlasciwosci,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'powierzchnieAplikacji',
      displayName: 'Powierzchnia aplikacji',
      description: 'Powierzchnie, na które można aplikować etykietę.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.powierzchnie_aplikacji,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'okresyAplikacji',
      displayName: 'Okres aplikacji',
      description: 'Zalecany okres aplikacji dla etykiety.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.okres_aplikacji,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'grubosciMaterialowWierzchnich',
      displayName: 'Grubość materiału wierzchniego',
      description: 'Grubość materiału wierzchniego podana w µm.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.grubosc_materialu_wierzchniego,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'gramaturyMaterialowWierzchnich',
      displayName: 'Gramatura materiału wierzchniego',
      description: 'Gramatura materiału wierzchniego podana w g/m².',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.gramatura_materialu_wierzchniego,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'grubosciPodkladow',
      displayName: 'Grubość podkładu',
      description: 'Grubość podkładu etykiety podana w µm.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.grubosc_podkladu,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'grubosciCalkowite',
      displayName: 'Grubość całkowita',
      description: 'Całkowita grubość etykiety podana w µm.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.grubosc_calkowita,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'przyczepnosci',
      displayName: 'Przyczepność',
      description: 'Przyczepność etykiety zgodnie z normą FTM9.',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.przyczepnosc,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'minimalneTemperaturyAplikacji',
      displayName: 'Minimalna temperatura aplikacji',
      description: 'Minimalna temperatura, w której można aplikować etykietę (°C).',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.minimalna_temperatura_aplikacji,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'minimalneTemperaturyUzytkownia',
      displayName: 'Minimalna temperatura użytkowania',
      description: 'Minimalna temperatura, w której można używać etykietę (°C).',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.minimalna_temperatura_uzytkowania,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksymalneTemperaturyUzytkowania',
      displayName: 'Maksymalna temperatura użytkowania',
      description: 'Maksymalna temperatura, w której można używać etykietę (°C).',
      featureSetArray: [],
      getFeatureFromProduct: (etykieta: Etykieta) => etykieta.maksymalna_temperatura_uzytkowania,
      isNumber: false,
      isArray: false,
    },
  ];

  printerFeatureConfigs: FeatureConfig<Drukarka, any>[] = [
    {
      name: 'producenci',
      displayName: 'Producenci',
      description: 'Producent drukarki.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.producent,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'prowadzenia',
      displayName: 'Prowadzenia',
      description: 'Typ prowadzenia drukarki.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.prowadzenie,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'metodyWydruku',
      displayName: 'Metody wydruku',
      description: 'Metody wydruku obsługiwane przez drukarkę.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.metody_wydruku,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'rodzajeGlowicy',
      displayName: 'Rodzaje głowicy',
      description: 'Typy głowic drukarek.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.rodzaj_glowicy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'ekrany',
      displayName: 'Ekrany',
      description: 'Typ ekranu w drukarce.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.ekran,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'interfejsy',
      displayName: 'Interfejsy',
      description: 'Dostępne interfejsy komunikacyjne drukarki.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.interfejsy_all,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'wlasciwosci',
      displayName: 'Właściwości',
      description: 'Kluczowe właściwości drukarki.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.wlasciwosci_all,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'oprogramowanie',
      displayName: 'Oprogramowanie',
      description: 'Oprogramowanie wspierane przez drukarkę.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.oprogramowanie,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'sterowniki',
      displayName: 'Sterowniki',
      description: 'Dostępne sterowniki drukarki.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.sterowniki,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'typyNawoju',
      displayName: 'Typy nawoju',
      description: 'Obsługiwane typy nawoju taśmy w drukarce.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.typy_nawoju,
      isNumber: false,
      isArray: true,
    },
    {
      name: 'szerokosciGlowicy',
      displayName: 'Szerokości głowicy',
      description: 'Szerokość głowicy w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.szerokosc_glowicy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'rozdzielczosciGlowicy',
      displayName: 'Rozdzielczości głowicy',
      description: 'Rozdzielczość głowicy w dpi.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.rozdzielczosc_glowicy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksPredkosciDrukuTermicznego',
      displayName: 'Maks. prędkości druku',
      description: 'Maksymalna prędkość druku w mm/s.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_predkosc_druku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksSzerokosciWydruku',
      displayName: 'Maks. szerokości wydruku',
      description: 'Maksymalna szerokość wydruku w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_szerokosc_wydruku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'minDlugosciWydruku',
      displayName: 'Min. długości wydruku',
      description: 'Minimalna długość wydruku w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.min_dlugosc_wydruku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksDlugosciWydruku',
      displayName: 'Maks. długości wydruku',
      description: 'Maksymalna długość wydruku w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_dlugosc_wydruku,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'szerokosciDrukarki',
      displayName: 'Szerokości drukarki',
      description: 'Szerokość drukarki w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.szerokosc_drukarki,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'glebokosciDrukarki',
      displayName: 'Głębokości drukarki',
      description: 'Głębokość drukarki w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.glebokosc_drukarki,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'wysokosciDrukarki',
      displayName: 'Wysokości drukarki',
      description: 'Wysokość drukarki w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.wysokosc_drukarki,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'wagiDrukarki',
      displayName: 'Wagi drukarki',
      description: 'Waga drukarki w kg.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.waga_drukarki,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'minSzerokosciMaterialu',
      displayName: 'Min. szerokości materiału',
      description: 'Minimalna szerokość materiału w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.min_szerokosc_materialu,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksSzerokosciMaterialu',
      displayName: 'Maks. szerokości materiału',
      description: 'Maksymalna szerokość materiału w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_szerokosc_materialu,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksSredniceRolkiMaterialu',
      displayName: 'Maks. średnice rolki materiału',
      description: 'Maksymalna średnica rolki materiału w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_srednica_rolki_materialu,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'sredniceGilzMaterialu',
      displayName: 'Średnice gilz materiału',
      description: 'Średnice gilz materiału w calach.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.srednice_gilz_materialu,
      isNumber: true,
      isArray: true,
    },
    {
      name: 'minSzerokosciTasmy',
      displayName: 'Min. szerokości taśmy',
      description: 'Minimalna szerokość taśmy w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.min_szerokosc_tasmy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksSzerokosciTasmy',
      displayName: 'Maks. szerokości taśmy',
      description: 'Maksymalna szerokość taśmy w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_szerokosc_tasmy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksSredniceRolkiTasmy',
      displayName: 'Maks. średnice rolki taśmy',
      description: 'Maksymalna średnica rolki taśmy w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_srednica_rolki_tasmy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'maksDlugosciNawojuTasmy',
      displayName: 'Maks. długości nawoju taśmy',
      description: 'Maksymalna długość nawoju taśmy w metrach.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.maks_dlugosc_nawoju_tasmy,
      isNumber: false,
      isArray: false,
    },
    {
      name: 'sredniceGilzTasmy',
      displayName: 'Średnice gilz taśmy',
      description: 'Średnice gilz taśmy w mm.',
      featureSetArray: [],
      getFeatureFromProduct: (drukarka: Drukarka) => drukarka.srednice_gilz_tasmy,
      isNumber: true,
      isArray: true,
    },
  ];
  featureConfigsMap: { [key: number]: FeatureConfig<any, any>[] } = {
    0: this.tasmyFeatureConfigs, // Tasmy
    1: this.textileFeatureConfigs, // Tekstylia
    2: this.labelFeatureConfigs, // Etykiety
    3: this.printerFeatureConfigs, // Drukarki
  };

  allTasmy: Tasma[] = [];
  filteredTasmy: Tasma[] = [];

  allTekstylia: Textile[] = [];
  filteredTekstylia: Textile[] = [];

  allEtykiety: Etykieta[] = [];
  filteredEtykiety: Etykieta[] = [];

  allDrukarki: Drukarka[] = [];
  filteredDrukarki: Drukarka[] = [];

  allAplikatory: Aplikator[] = [];

  allNawijaki: Nawijak[] = [];

  tasmyCategoriesExpanded: { [key: string]: boolean } = {};
  tekstyliaCategoriesExpanded: { [key: string]: boolean } = {};
  etykietyCategoriesExpanded: { [key: string]: boolean } = {};
  drukarkiCategoriesExpanded: { [key: string]: boolean } = {};

  slug: string | null = '';
  private routeSub!: Subscription;
  animationStateTitle: boolean = false;
  animationStateList: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformID: Object,
              private cdr: ChangeDetectorRef,
              // private route: ActivatedRoute,
              private router: Router,
              private tasmaService: TasmaDataService,
              private textileService: TextileDataService,
              private labelService: LabelDataService,
              private printerService: PrinterDataService,
              private aplikatoryService: AplikatoryDataService,
              private nawijakiService: NawijakiDataService) {}

  ngOnInit() {
    // if (isPlatformBrowser(this.platformID)) {
    // this.setCategoryOnSlug();
    this.setCategoryOnSlug();
    // console.log(this.animationState);
    this.routeSub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setCategoryOnSlug();
      this.animationStateTitle = !this.animationStateTitle;
      this.animationStateList = !this.animationStateList;
    });
    // this.loadData();
    // }
  }

  setCategoryOnSlug(){
    // if (this.route.snapshot.routeConfig && 'path' in this.route.snapshot.routeConfig) {
    //   this.slug = this.route.snapshot.routeConfig.path || null;
    // }
    if (this.router.url){
      this.slug = this.router.url;
    }
    // console.log(this.router.url);
    if (this.slug == '/tasmy-termotransferowe') {
      this.active_category = 0;
    } else if (this.slug == '/tekstylia') {
      this.active_category = 1;
    } else if (this.slug == '/etykiety') {
      this.active_category = 2;
    } else if (this.slug == '/drukarki') {
      this.active_category = 3;
    } else if (this.slug == '/aplikatory') {
      this.active_category = 4;
    } else if (this.slug == '/nawijaki') {
      this.active_category = 5;
    }
    this.loadData();
  }

  loadData(){
    this.filtryOpened = false;
    if (this.active_category === 0) {
      if (this.filteredTasmy.length === 0) {
        this.tasmyFeatureConfigs.forEach(config => {
          this.tasmyCategoriesExpanded[config.name] = false;
        });
        this.tasmaService.fetchAllTasmy().subscribe({
          error: error => console.error('Error fetching data', error),
          complete: () => {
            this.fetchInitialData(
              this.tasmaService,
              'tasmyCache',
              this.tasmyFeatureConfigs,
              data => (this.allTasmy = data),
              data => (this.filteredTasmy = data)
            );
            this.tasmaService.updateFilteredItems(this.filteredTasmy);
          }
        });
      } else {
          this.number = this.filteredTasmy.length;
      }
    } else if (this.active_category === 1) {
      if (this.filteredTekstylia.length === 0) {
        this.textileFeatureConfigs.forEach(config => {
          this.tekstyliaCategoriesExpanded[config.name] = false;
        });
        this.textileService.fetchAllTekstylia().subscribe({
          error: error => console.error('Error fetching data', error),
          complete: () => {
            this.fetchInitialData(
              this.textileService,
              'tekstyliaCache',
              this.textileFeatureConfigs,
              data => (this.allTekstylia = data),
              data => (this.filteredTekstylia = data)
            );
            this.textileService.updateFilteredItems(this.filteredTekstylia);
          }
        });
      }else {
        this.number = this.filteredTekstylia.length;
      }
    } else if (this.active_category === 2) {
      if (this.filteredEtykiety.length === 0) {
        this.labelFeatureConfigs.forEach(config => {
          this.etykietyCategoriesExpanded[config.name] = false;
        });
        this.labelService.fetchAllEtykiety().subscribe({
          error: error => console.error('Error fetching data', error),
          complete: () => {
            this.fetchInitialData(
              this.labelService,
              'etykietyCache',
              this.labelFeatureConfigs,
              data => (this.allEtykiety = data),
              data => (this.filteredEtykiety = data)
            );
            this.labelService.updateFilteredItems(this.filteredEtykiety);
          }
        });
      }else{
        this.number = this.filteredEtykiety.length;
      }
    } else if (this.active_category === 3) {
      if (this.filteredDrukarki.length === 0) {
        this.printerFeatureConfigs.forEach(config => {
          this.drukarkiCategoriesExpanded[config.name] = false;
        });
        this.printerService.fetchAllDrukarki().subscribe({
          error: error => console.error('Error fetching data', error),
          complete: () => {
            this.fetchInitialData(
              this.printerService,
              'drukarkiCache',
              this.printerFeatureConfigs,
              data => (this.allDrukarki = data),
              data => (this.filteredDrukarki = data)
            );
            this.printerService.updateFilteredItems(this.filteredDrukarki);
          }
        });
      }else{
        this.number = this.filteredDrukarki.length;
      }
    } else if (this.active_category === 4) {
      this.allAplikatory = Array.from(this.aplikatoryService.aplikatoryCache.values());
      this.number = this.allAplikatory.length;
    } else if (this.active_category === 5) {
      this.allNawijaki = Array.from(this.nawijakiService.nawijakiCache.values());
      this.number = this.allNawijaki.length;
    }
  }
  fetchInitialData(service: any, cacheKey: string, activeConfigs: FeatureConfig<any, any>[], setAllProducts: (data: any[]) => void, setFilteredProducts: (data: any[]) => void) {
    const allProducts = Array.from(service[cacheKey].values());
    setAllProducts(allProducts);
    setFilteredProducts([...allProducts]);
    this.number = [...allProducts].length
    this.initializeFeatureConfigs(activeConfigs, allProducts);
  }

  initializeFeatureConfigs(featureConfigs: FeatureConfig<any, any>[], allProducts: any[]) {
    featureConfigs.forEach(config => {
      const featuresSet = new Map<number, any>();
      allProducts.forEach(product => {
        const features = config.getFeatureFromProduct(product);

        if (features == null) {
          // Jeśli cecha jest `null` lub `undefined`, pomijamy produkt
          return;
        }

        if (config.isArray && Array.isArray(features)) {

          // features.forEach((feature: any) => featuresSet.set(feature.id, feature));
          features.forEach((feature: any) => {
            if (feature) {
              if (feature.id !== undefined) {
                featuresSet.set(feature.id, feature);
              } else {
                featuresSet.set(feature, feature);

              }
            }
          });
        } else if (features) {
          featuresSet.set(features.id || features, features);
        }
      });

      const featureArray = Array.from(featuresSet.values()).map(feature => ({
        key: feature,
        value: false,
        disabled: false,
      }));

      // Sortowanie wartości liczbowych, jeśli klucz `key` jest liczbą
      if (featureArray.length > 0 && typeof featureArray[0].key === 'number') {
        featureArray.sort((a, b) => a.key - b.key);
      }

      config.featureSetArray = featureArray;
    });
  }

  clearFilters() {
    this.filteredTasmy = [...this.allTasmy];
    this.tasmyFeatureConfigs.forEach(config => {
      config.featureSetArray.forEach(feature => {
        feature.value = false;
        feature.disabled = false;
      });
      this.tasmyCategoriesExpanded[config.name] = false;
    });
    this.filteredTekstylia = [...this.allTekstylia];
    this.textileFeatureConfigs.forEach(config => {
      config.featureSetArray.forEach(feature => {
        feature.value = false;
        feature.disabled = false;
      });
      this.tekstyliaCategoriesExpanded[config.name] = false;
    });
    this.filteredEtykiety = [...this.allEtykiety];
    this.labelFeatureConfigs.forEach(config => {
      config.featureSetArray.forEach(feature => {
        feature.value = false;
        feature.disabled = false;
      });
      this.etykietyCategoriesExpanded[config.name] = false;
    });
    this.filteredDrukarki = [...this.allDrukarki];
    this.printerFeatureConfigs.forEach(config => {
      config.featureSetArray.forEach(feature => {
        feature.value = false;
        feature.disabled = false;
      });
      this.drukarkiCategoriesExpanded[config.name] = false;
    });
    // this.updateTasmyService();
    // this.updateTextileService();
    // this.updateLabelService();
    // this.updatePrinterService();
    this.loadData();
    this.animationStateList = !this.animationStateList;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  prepareRoute(outlet: RouterOutlet) {
    return (outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']) || 'default';
  }

  async collectFilterEvent(category: string, option: string, toggle: boolean): Promise<void> {
    // @ts-ignore
    if (window['saitag'] && window['saitag'].collectFilterEvent) {
      // @ts-ignore
      window['saitag'].collectFilterEvent('Tasmy filtry', category, option, toggle);
    }
  }
  updateScrollLock() {
    const isMobile = window.innerWidth <= 600;

    if (this.filtryOpened && isMobile) {
      document.body.style.overflow = 'hidden'; // Blokada scrollowania
    } else {
      document.body.style.overflow = ''; // Przywrócenie scrollowania
    }
  }

  updateTasmyService(){
    this.tasmaService.updateFilteredItems(this.filteredTasmy);
    this.animationStateList = !this.animationStateList;
  }

  updateTextileService(){
    this.textileService.updateFilteredItems(this.filteredTekstylia);
    this.animationStateList = !this.animationStateList;

  }

  updateLabelService(){
    this.labelService.updateFilteredItems(this.filteredEtykiety);
    this.animationStateList = !this.animationStateList;
  }

  updatePrinterService(){
    this.printerService.updateFilteredItems(this.filteredDrukarki);
    this.animationStateList = !this.animationStateList;
  }

  categoryChanged(category: number){
    // this.active_category = category;
    // this.loadData();
    this.updateScrollLock();
  }
  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
}
