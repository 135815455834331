import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-unwind-description',
  imports: [],
  templateUrl: './unwind-description.component.html',
  standalone: true,
  styleUrl: './unwind-description.component.css'
})
export class UnwindDescriptionComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() active = false;
  @Output() toggled = new EventEmitter<boolean>();

  toggleActive(){
    // this.active = !this.active;
    this.toggled.emit(this.active);
  }

}
