import { Component } from '@angular/core';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-drop-down-products',
  imports: [
    RouterLink
  ],
  templateUrl: './drop-down-products.component.html',
  standalone: true,
  styleUrl: './drop-down-products.component.css'
})
export class DropDownProductsComponent {

}
