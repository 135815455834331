import {Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import {BaseCecha, ExtendedCecha, Tasma} from "../tasma-data/tasma-data.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {HostAddressService} from "../host-address/host-address.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {Textile, TextileKoloryCecha} from "../textile-data/textile-data.service";
import {response} from "express";
import {Drukarka} from '../printer-data/printer-data.service';

export interface Etykieta {
   id: number,
   name: string,
   material_wierzchni: BaseCecha,
   druk: BaseCecha,
   powierzchnia: BaseCecha,
   klej: BaseCecha,
   kolory: ExtendedCecha[],
   grubosc_materialu_wierzchniego: number,
   gramatura_materialu_wierzchniego: number,
   grubosc_podkladu: number,
   grubosc_calkowita: number,
   przyczepnosc: number,
   minimalna_temperatura_aplikacji: number,
   minimalna_temperatura_uzytkowania: number,
   maksymalna_temperatura_uzytkowania: number,
   przeznaczenia: BaseCecha[],
   wlasciwosci: BaseCecha[],
   okres_aplikacji: BaseCecha,
   powierzchnie_aplikacji: BaseCecha[],
   polecane_tasmy: number[],
   slug: string,
   opis: string,
   meta: string,
   name_long: string
 }

interface EtykietyRes {
  id: number,
  name: string,
  material_wierzchni: number,
  druk: number,
  powierzchnia: number,
  klej: number,
  kolory: number[],
  grubosc_materialu_wierzchniego: number,
  gramatura_materialu_wierzchniego: number,
  grubosc_podkladu: number,
  grubosc_calkowita: number,
  przyczepnosc: number,
  minimalna_temperatura_aplikacji: number,
  minimalna_temperatura_uzytkowania: number,
  maksymalna_temperatura_uzytkowania: number,
  przeznaczenia: number[],
  wlasciwosci: number[],
  okres_aplikacji: number,
  powierzchnie_aplikacji: number[],
  polecane_tasmy: number[],
  slug: string,
  opis: string,
  meta: string,
  name_long: string
}

interface Res {
  etykiety: EtykietyRes[],
  kolory: ExtendedCecha[],
  druki: BaseCecha[],
  kleje: BaseCecha[],
  materialyWierzchnie: BaseCecha[],
  okresyAplikacji: BaseCecha[],
  powierzchnie: BaseCecha[],
  powierzchnieAplikacji: BaseCecha[],
  przeznaczenia: BaseCecha[],
  wlasciwosci: BaseCecha[],
  grubosciMaterialowWierzchnich: number[],
  gramaturyMaterialowWierzchnich:number[],
  grubosciPodkladow: number[],
  grubosciCalkowite: number[],
  przyczepnosci: number[],
  minimalneTemperaturyAplikacji:number[],
  minimalneTemperaturyUzytkownia:number[],
  maksymalneTemperaturyUzytkowania:number[]
}

@Injectable({
  providedIn: 'root'
})
export class LabelDataService {
  get grubosciCalkowiteCache(): Map<number, number> {
    return this._grubosciCalkowiteCache;
  }
  get wlasciwosciCache(): Map<number, BaseCecha> {
    return this._wlasciwosciCache;
  }
  get maksymalneTemperaturyUzytkowaniaCache(): Map<number, number> {
    return this._maksymalneTemperaturyUzytkowaniaCache;
  }
  get minimalneTemperaturyUzytkowniaCache(): Map<number, number> {
    return this._minimalneTemperaturyUzytkowniaCache;
  }
  get minimalneTemperaturyAplikacjiCache(): Map<number, number> {
    return this._minimalneTemperaturyAplikacjiCache;
  }
  get przyczepnosciCache(): Map<number, number> {
    return this._przyczepnosciCache;
  }
  get grubosciPodkladowCache(): Map<number, number> {
    return this._grubosciPodkladowCache;
  }
  get gramaturyMaterialowWierzchnichCache(): Map<number, number> {
    return this._gramaturyMaterialowWierzchnichCache;
  }
  get grubosciMaterialowWierzchnichCache(): Map<number, number> {
    return this._grubosciMaterialowWierzchnichCache;
  }
  get przeznaczeniaCache(): Map<number, BaseCecha> {
    return this._przeznaczeniaCache;
  }
  get powierzchnieAplikacjiCache(): Map<number, BaseCecha> {
    return this._powierzchnieAplikacjiCache;
  }
  get powierzchnieCache(): Map<number, BaseCecha> {
    return this._powierzchnieCache;
  }
  get okresyAplikacjiCache(): Map<number, BaseCecha> {
    return this._okresyAplikacjiCache;
  }
  get materialyWierzchnieCache(): Map<number, BaseCecha> {
    return this._materialyWierzchnieCache;
  }
  get klejeCache(): Map<number, BaseCecha> {
    return this._klejeCache;
  }
  get drukiCache(): Map<number, BaseCecha> {
    return this._drukiCache;
  }
  get koloryCache(): Map<number, BaseCecha> {
    return this._koloryCache;
  }
  get etykietyCache(): Map<number, Etykieta> {
    return this._etykietyCache;
  }

  private _etykietyCache: Map<number, Etykieta> = new Map();
  private _koloryCache: Map<number, ExtendedCecha> = new Map();
  private _drukiCache: Map<number, BaseCecha> = new Map();
  private _klejeCache: Map<number, BaseCecha> = new Map();
  private _materialyWierzchnieCache: Map<number, BaseCecha> = new Map();
  private _okresyAplikacjiCache: Map<number, BaseCecha> = new Map();
  private _powierzchnieCache: Map<number, BaseCecha> = new Map();
  private _powierzchnieAplikacjiCache: Map<number, BaseCecha> = new Map();
  private _przeznaczeniaCache: Map<number, BaseCecha> = new Map();
  private _wlasciwosciCache: Map<number, BaseCecha> = new Map();
  private _grubosciMaterialowWierzchnichCache: Map<number, number> = new Map();
  private _gramaturyMaterialowWierzchnichCache: Map<number, number> = new Map();
  private _grubosciPodkladowCache: Map<number, number> = new Map();
  private _grubosciCalkowiteCache: Map<number, number> = new Map();
  private _przyczepnosciCache: Map<number, number> = new Map();
  private _minimalneTemperaturyAplikacjiCache: Map<number, number> = new Map();
  private _minimalneTemperaturyUzytkowniaCache: Map<number, number> = new Map();
  private _maksymalneTemperaturyUzytkowaniaCache: Map<number, number> = new Map();

  private _dataFetched = false;

  private key: StateKey<Res> = makeStateKey("etykietyKey");
  private isAllkey: StateKey<boolean> = makeStateKey("isAllEtykietyKey");

  private filteredItemsSubject = new BehaviorSubject<Etykieta[]>([]);
  filteredItems$ = this.filteredItemsSubject.asObservable();

  constructor(private http: HttpClient, private  hostAddress: HostAddressService,
              private state: TransferState) { }

  fetchAllEtykiety(): Observable<void> {
    if (this._dataFetched) {
      return of();
    }
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/etykietyAll'//, { nazwy: uncachedNazwy }
    ).pipe(
      map(response => {
        this.processResponse(response);
        this._dataFetched = true;
        return;
      })
    );
  }

  fetchEtykiety(ids: number[]): Observable<Etykieta[]> {
    const uncachedIds = ids.filter(id => ![...this._etykietyCache.values()].some(etykieta => etykieta.id === id));
    if (uncachedIds.length === 0) {
      const cachedEtykiety = [...this._etykietyCache.entries()]
        .filter(([id, _]) => ids.includes(id))
        .map(([_, etykiety]) => etykiety);

      return of(cachedEtykiety);
    }

    let params = new HttpParams();
    uncachedIds.forEach(id => {
      params = params.append('ids', id);
    });

    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/etykiety', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  fetchEtykietyBySlug(slug: string): Observable<Etykieta[]>{
    for (let etykieta of this._etykietyCache.values()) {
      if (etykieta.slug === slug) {
        return of([etykieta]);
      }
    }

    let params = new HttpParams();
    params = params.append('slug', slug);
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/etykietyBySlug', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  processResponse(response: Res): Etykieta[] {
    response.kolory.forEach(item => {
      if (!this._koloryCache.has(item.id)) {
        this._koloryCache.set(item.id, item);
      }
    });

    response.druki.forEach(item => {
      if (!this._drukiCache.has(item.id)) {
        this._drukiCache.set(item.id, item);
      }
    });

    response.druki.forEach(item => {
      if (!this._drukiCache.has(item.id)) {
        this._drukiCache.set(item.id, item);
      }
    });

    response.kleje.forEach(item => {
      if (!this._klejeCache.has(item.id)) {
        this._klejeCache.set(item.id, item);
      }
    });

    response.materialyWierzchnie.forEach(item => {
      if (!this._materialyWierzchnieCache.has(item.id)) {
        this._materialyWierzchnieCache.set(item.id, item);
      }
    });

    response.okresyAplikacji.forEach(item => {
      if (!this._okresyAplikacjiCache.has(item.id)) {
        this._okresyAplikacjiCache.set(item.id, item);
      }
    });

    response.powierzchnie.forEach(item => {
      if (!this._powierzchnieCache.has(item.id)) {
        this._powierzchnieCache.set(item.id, item);
      }
    });

    response.powierzchnieAplikacji.forEach(item => {
      if (!this._powierzchnieAplikacjiCache.has(item.id)) {
        this._powierzchnieAplikacjiCache.set(item.id, item);
      }
    });

    response.przeznaczenia.forEach(item => {
      if (!this._przeznaczeniaCache.has(item.id)) {
        this._przeznaczeniaCache.set(item.id, item);
      }
    });

    response.wlasciwosci.forEach(item => {
      if (!this._wlasciwosciCache.has(item.id)) {
        this._wlasciwosciCache.set(item.id, item);
      }
    });

    response.grubosciMaterialowWierzchnich.forEach(item => {
      if (!this._grubosciMaterialowWierzchnichCache.has(item) && item !== null) {
        this._grubosciMaterialowWierzchnichCache.set(item, item);
      }
    });

    response.gramaturyMaterialowWierzchnich.forEach(item => {
      if (!this._gramaturyMaterialowWierzchnichCache.has(item) && item !== null) {
        this._gramaturyMaterialowWierzchnichCache.set(item, item);
      }
    });

    response.grubosciPodkladow.forEach(item => {
      if (!this._grubosciPodkladowCache.has(item) && item !== null) {
        this._grubosciPodkladowCache.set(item, item);
      }
    });

    response.grubosciCalkowite.forEach(item => {
      if (!this._grubosciCalkowiteCache.has(item) && item !== null) {
        this._grubosciCalkowiteCache.set(item, item);
      }
    });

    response.przyczepnosci.forEach(item => {
      if (!this._przyczepnosciCache.has(item) && item !== null) {
        this._przyczepnosciCache.set(item, item);
      }
    });

    response.minimalneTemperaturyAplikacji.forEach(item => {
      if (!this._minimalneTemperaturyAplikacjiCache.has(item) && item !== null) {
        this._minimalneTemperaturyAplikacjiCache.set(item, item);
      }
    });

    response.minimalneTemperaturyUzytkownia.forEach(item => {
      if (!this._minimalneTemperaturyUzytkowniaCache.has(item) && item !== null) {
        this._minimalneTemperaturyUzytkowniaCache.set(item, item);
      }
    });

    response.maksymalneTemperaturyUzytkowania.forEach(item => {
      if (!this._maksymalneTemperaturyUzytkowaniaCache.has(item) && item !== null) {
        this._maksymalneTemperaturyUzytkowaniaCache.set(item, item);
      }
    });

    const etykiety: Etykieta[] = response.etykiety.map((etykietyRes: EtykietyRes) => {
      if (!this._etykietyCache.has(etykietyRes.id)) {
        const etykieta: Etykieta = {
          ...etykietyRes,
          material_wierzchni: this._materialyWierzchnieCache.get(etykietyRes.material_wierzchni)!,
          druk: this._drukiCache.get(etykietyRes.druk)!,
          powierzchnia: this._powierzchnieCache.get(etykietyRes.powierzchnia)!,
          klej: this._klejeCache.get(etykietyRes.klej)!,
          kolory: etykietyRes.kolory.map(id => this._koloryCache.get(id)!),
          przeznaczenia: etykietyRes.przeznaczenia.map(id => this._przeznaczeniaCache.get(id)!),
          wlasciwosci: etykietyRes.wlasciwosci.map(id => this._wlasciwosciCache.get(id)!),
          okres_aplikacji: this._okresyAplikacjiCache.get(etykietyRes.okres_aplikacji)!,
          powierzchnie_aplikacji: etykietyRes.powierzchnie_aplikacji.map(id => this._powierzchnieAplikacjiCache.get(id)!),
        };
        this._etykietyCache.set(etykietyRes.id, etykieta);
        return etykieta;
      }
      return this._etykietyCache.get(etykietyRes.id)!;
    });
    return etykiety;
  }
  setState(etykiety: Res) {
    this.state.set(this.key, etykiety);
  }
  setStateIsAll() {
    // console.log(tasmy);
    this.state.set(this.isAllkey, true);
  }

  loadState(){
    const res = this.state.get(this.key, null);
    if (res) {
      this.processResponse(res);
    }
    this._dataFetched = this.state.get(this.isAllkey, false);
  }

  updateFilteredItems(newItems: Etykieta[]): void {
    this.filteredItemsSubject.next(newItems);
  }

  // Ewentualnie dodatkowa metoda pobierająca aktualną wartość
  getFilteredItems(): Etykieta[] {
    return this.filteredItemsSubject.getValue();
  }
}
