import {Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import {BehaviorSubject, map, Observable, of} from 'rxjs';
import {HostAddressService} from "../host-address/host-address.service";
import {response} from 'express';


export interface ExtendedCecha extends BaseCecha{
  typ: number;
  wartosc: string;
}

export interface BaseCecha {
  id: number;
  nazwa: string;
}

// export interface Material {
//   id: number;
//   nazwa: string;
// }
//
// export interface Zastosowanie {
//   id: number;
//   nazwa: string;
// }
// export interface Typ {
//   id: number;
//   nazwa: string;
// }
//
// export interface OdpornoscMechaniczna {
//   id: number;
//   nazwa: string;
// }
//
// export interface OdpornoscChemiczna {
//   id: number;
//   nazwa: string;
// }
//
// export interface Czulosc {
//   id: number;
//   nazwa: string;
// }
//
// export interface OdpornoscTemperaturowa {
//   wartosc: number;
// }
//
// export interface Szybkosc {
//   wartosc: number;
// }

export interface Tasma {
  id: number; //
  nazwa: string; //
  kolory: ExtendedCecha[]; //
  glowice: BaseCecha[]; //
  typ: BaseCecha; //
  szybkosc_zadruku: number; //
  odpornosc_temperaturowa: number; //
  odpornosc_mechaniczna: number; //
  odpornosc_chemiczna: number; //
  czulosc: number; //
  // odpornosc_mechaniczna_group: BaseCecha;
  // odpornosc_chemiczna_group: BaseCecha;
  // czulosc_group: BaseCecha;
  opis: string;
  zastosowania: BaseCecha[]; //
  materialy: BaseCecha[]; //
  img: string; //
  display_type: number; //
  display_spec: string; //
  slug: string; //
  meta: string; //
}

interface TasmyRes {
  id: number;
  nazwa: string;
  kolory: number[];
  glowice: number[];
  typ: number;
  szybkosc_zadruku: number;
  odpornosc_temperaturowa: number;
  odpornosc_mechaniczna: number;
  odpornosc_chemiczna: number;
  czulosc: number;
  // odpornosc_mechaniczna_group: number;
  // odpornosc_chemiczna_group: number;
  // czulosc_group: number;
  opis: string;
  zastosowania: number[];
  materialy: number[];
  img: string;
  display_type: number;
  display_spec: string;
  slug: string;
  meta: string;
}

interface Res {
  tasmy: TasmyRes[];
  kolory: ExtendedCecha[];
  typy: BaseCecha[];
  glowice: BaseCecha[];
  zastosowania: BaseCecha[];
  materialy: BaseCecha[];
  czulosci: number[];
  odpornosci_mechaniczne: number[];
  odpornosci_chemiczne: number[];
  odpornosci_temperaturowe: number[];
  szybkosci: number[];
}


@Injectable({
  providedIn: 'root'
})
export class TasmaDataService {
  // get dataFetched(): boolean {
  //   return this._dataFetched;
  // }
  //
  // set dataFetched(value: boolean) {
  //   this._dataFetched = value;
  // }
  private _koloryCache: Map<number, ExtendedCecha> = new Map();
  private _glowiceCache: Map<number, BaseCecha> = new Map();
  private _zastosowaniaCache: Map<number, BaseCecha> = new Map();
  private _tasmyCache: Map<number, Tasma> = new Map();
  private _typyCache: Map<number, BaseCecha> = new Map();
  private _materialyCache: Map<number, BaseCecha> = new Map();
  private _szybkoscCache: Map<number, number> = new Map();
  private _odpornoscTemperaturowaCache: Map<number, number> = new Map();
  private _odpornoscChemicznaCache: Map<number, number> = new Map();
  private _odpornoscMechanicznaCache: Map<number, number> = new Map();
  private _czuloscCache: Map<number, number> = new Map();


  private _dataFetched = false;

  private key: StateKey<Res> = makeStateKey("tasmyKey");
  private isAllkey: StateKey<boolean> = makeStateKey("isAllKey");

  private filteredItemsSubject = new BehaviorSubject<Tasma[]>([]);
  filteredItems$ = this.filteredItemsSubject.asObservable();


  constructor(private http: HttpClient, private  hostAddress: HostAddressService,
              private state: TransferState) {}

  get tasmyCache(): Map<number, Tasma>{
    return this._tasmyCache;
  }
  // get typyCache(): Map<number, BaseCecha> {
  //     return this._typyCache;
  // }
  get koloryCache(): Map<number, ExtendedCecha>{
    return this._koloryCache;
  }
  get glowiceCache(): Map<number, BaseCecha>{
    return this._glowiceCache;
  }
  get zastosowaniaCache(): Map<number, BaseCecha>{
    return this._zastosowaniaCache;
  }

  get materialyCache(): Map<number, BaseCecha>{
    return this._materialyCache;
  }

  get czuloscCache(): Map<number, number>{
    return this._czuloscCache;
  }
  get odpornoscMechanicznaCache(): Map<number, number>{
    return this._odpornoscMechanicznaCache;
  }
  get odpornoscChemicznaCache(): Map<number, number>{
    return this._odpornoscChemicznaCache;
  }
  get odpornoscTemperaturowaCache(): Map<number, number>{
    return this._odpornoscTemperaturowaCache;
  }
  get szybkoscCache(): Map<number, number> {
    return this._szybkoscCache;
  }

  fetchAllTasmy(): Observable<void> {
    if (this._dataFetched) {
      return of();
    }
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tasmyAll'//, { nazwy: uncachedNazwy }
    ).pipe(
      map(response => {
        this.processResponse(response);
        this._dataFetched = true;
        return;
      })
    );
  }

  fetchTasmy(ids: number[]): Observable<Tasma[]> {
    const uncachedIds = ids.filter(id => ![...this._tasmyCache.values()].some(tasma => tasma.id === id));
    if (uncachedIds.length === 0) {
      const cachedTasmy = [...this._tasmyCache.entries()]
        .filter(([id, _]) => ids.includes(id))
        .map(([_, tasma]) => tasma);

      return of(cachedTasmy);
    }

    let params = new HttpParams();
    uncachedIds.forEach(id => {
      params = params.append('ids', id);
    });

    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tasmy', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  // fetchTasmaByNazwa(nazwa: string): Observable<Tasma[]>{
  //   for (let tasma of this._tasmyCache.values()) {
  //     if (tasma.nazwa === nazwa) {
  //       return of([tasma]);
  //     }
  //   }
  //
  //   let params = new HttpParams();
  //   params = params.append('nazwa', nazwa);
  //   return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tasmaByName', { params }).pipe(
  //     map(response => this.processResponse(response))
  //   );
  // }

  fetchTasmaBySlug(slug: string): Observable<Tasma[]>{
    for (let tasma of this._tasmyCache.values()) {
      if (tasma.slug === slug) {
        return of([tasma]);
      }
    }
    let params = new HttpParams();
    params = params.append('slug', slug);
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tasmaBySlug', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  processResponse(response: Res): Tasma[] {
    response.kolory.forEach(kolor => {
      if (!this._koloryCache.has(kolor.id)) {
        this._koloryCache.set(kolor.id, kolor);
      }
    });

    response.glowice.forEach(glowica => {
      if (!this._glowiceCache.has(glowica.id)) {
        this._glowiceCache.set(glowica.id, glowica);
      }
    });

    response.zastosowania.forEach(zastosowanie => {
      if (!this._zastosowaniaCache.has(zastosowanie.id)) {
        this._zastosowaniaCache.set(zastosowanie.id, zastosowanie);
      }
    });

    response.materialy.forEach(material => {
      if (!this._materialyCache.has(material.id)) {
        this._materialyCache.set(material.id, material);
      }
    });

    response.typy.forEach(typ => {
      if (!this._typyCache.has(typ.id)) {
        this._typyCache.set(typ.id, typ);
      }
    });

    response.czulosci.forEach(czulosc =>{
      if(!this._czuloscCache.has(czulosc)){
        this._czuloscCache.set(czulosc, czulosc)
      }
    });

    response.odpornosci_chemiczne.forEach(odpornoscChemiczna =>{
      if(!this._odpornoscChemicznaCache.has(odpornoscChemiczna)){
        this._odpornoscChemicznaCache.set(odpornoscChemiczna, odpornoscChemiczna)
      }
    });

    response.odpornosci_mechaniczne.forEach(odpornoscMechaniczna =>{
      if(!this._odpornoscMechanicznaCache.has(odpornoscMechaniczna)){
        this._odpornoscMechanicznaCache.set(odpornoscMechaniczna, odpornoscMechaniczna)
      }
    });

    response.odpornosci_temperaturowe.forEach(odpornoscTemperaturowa =>{
      if(!this._odpornoscTemperaturowaCache.has(odpornoscTemperaturowa)){
        this._odpornoscTemperaturowaCache.set(odpornoscTemperaturowa, odpornoscTemperaturowa)
      }
    });

    response.szybkosci.forEach(szybkosc =>{
      if(!this._szybkoscCache.has(szybkosc)){
        this._szybkoscCache.set(szybkosc, szybkosc)
      }
    });

    const tasmy: Tasma[] = response.tasmy.map(tasmaRes => {
      if (!this._tasmyCache.has(tasmaRes.id)) {
        const tasma: Tasma = {
          ...tasmaRes,
          kolory: tasmaRes.kolory.map(id => this._koloryCache.get(id)!),
          glowice: tasmaRes.glowice.map(id => this._glowiceCache.get(id)!),
          // typ: tasmaRes.typ.map(id => this.typyCache.get(id)!),
          typ: this._typyCache.get(tasmaRes.typ)!,
          // odpornosc_mechaniczna_group: this._odpornoscMechanicznaCache.get(tasmaRes.odpornosc_mechaniczna_group)!,
          // odpornosc_chemiczna_group: this._odpornoscChemicznaCache.get(tasmaRes.odpornosc_chemiczna_group)!,
          // czulosc_group: this._czuloscCache.get(tasmaRes.czulosc_group)!,
          zastosowania: tasmaRes.zastosowania.map(id => this._zastosowaniaCache.get(id)!),
          materialy: tasmaRes.materialy.map(id => this._materialyCache.get(id)!)
        };
        this._tasmyCache.set(tasmaRes.id, tasma);
        return tasma;
      }
      return this._tasmyCache.get(tasmaRes.id)!;
    });
    return tasmy;
  }

  // fetchLowPriorityData(): void {
  //   if (this.dataFetched) {
  //     return;
  //   }
  //
  //   const headers = this.httpHeaders.headers;
  //   // Example: Fetch a list of products
  //   this.http.get<Tasma[]>(this.hostAddress.getHostAddress() + 'get/tasmy',// JSON.stringify(ob),
  //       {headers}).pipe(
  //       tap(products => {
  //         products.forEach(product => this.cacheTasmy[product.nazwa] = product);
  //       }),
  //       catchError(this.handleError<Tasma[]>('fetchLowPriorityData', []))
  //   ).subscribe();
  // }

  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     console.error(error); // log to console instead
  //     return of(result as T);
  //   };
  // }

  setState(tasmy: Res) {
    this.state.set(this.key, tasmy);
  }
  setStateIsAll() {
    // console.log(tasmy);
    this.state.set(this.isAllkey, true);
  }

  loadState(){
    const res = this.state.get(this.key, null);
    if (res) {
      this.processResponse(res);
    }
    this._dataFetched = this.state.get(this.isAllkey, false);
  }

  updateFilteredItems(newItems: Tasma[]): void {
    this.filteredItemsSubject.next(newItems);
  }

  // Ewentualnie dodatkowa metoda pobierająca aktualną wartość
  getFilteredItems(): Tasma[] {
    return this.filteredItemsSubject.getValue();
  }
}
