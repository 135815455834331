import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {NavigationEnd, Router} from "@angular/router";
import {TasmaDataService} from './services/tasma-data/tasma-data.service';
import {TextileDataService} from './services/textile-data/textile-data.service';
import {LabelDataService} from './services/label-data/label-data.service';
import {RestService} from './services/rest/rest.service';
import {PrinterDataService} from './services/printer-data/printer-data.service';
import {filter} from 'rxjs';
import {isPlatformBrowser, NgIf} from '@angular/common';
import {DropDownProductsComponent} from './components/nav/drop-down-products/drop-down-products.component';
import {DropDownSolutionsComponent} from './components/nav/drop-down-solutions/drop-down-solutions.component';
import {DropDownKnowledgeComponent} from './components/nav/drop-down-knowledge/drop-down-knowledge.component';
// import {EnquiryWindowComponent} from './components/enquiry-window/enquiry-window.component';


@Component({
  selector: 'app-root',
  imports: [RouterOutlet, NgIf, DropDownProductsComponent, DropDownSolutionsComponent, DropDownKnowledgeComponent, RouterLink],
  templateUrl: './app.component.html',
  standalone: true,
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit{
  title = 'front';

  toastActive = false;

  productsMenuActive = false;
  knowledgeMenuActive = false;
  solutionsMenuActive = false;
  timeoutID: any;


  constructor(@Inject(PLATFORM_ID) private platformID: Object,
              private router: Router,
              private tasmaService: TasmaDataService,
              private textileService: TextileDataService,
              private labelService: LabelDataService,
              private printerService: PrinterDataService,
              private restService: RestService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformID)) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        // this.tasmaService.fetchTasmy([1,2,3,4]).subscribe(tasmy =>{
        //   console.log(tasmy);
        // });

        this.tasmaService.fetchAllTasmy().subscribe({
          error: (error) => {
            console.error('Error fetching data', error);
          }
        });
        this.textileService.fetchAllTekstylia().subscribe({
          error: (error) => {
            console.error('Error fetching data', error);
          }
        });
        this.labelService.fetchAllEtykiety().subscribe({
          error: (error) => {
            console.error('Error fetching data', error);
          }
        });
        this.printerService.fetchAllDrukarki().subscribe({
          error: (error) => {
            console.error('Error fetching data', error);
          }
        });
        this.onUrlChange();
        this.checkUTM();
      });
      this.showToast();
    }
  }

  private checkUTM(): void {
    if (localStorage.getItem('nUTM') == '1') {
      localStorage.setItem('nUTM', '0');
      let utmParams = localStorage.getItem('utmParams');
      // @ts-ignore
      if (window['saitag'] && window['saitag'].collectUTM) {
        // @ts-ignore
        window['saitag'].collectUTM(utmParams);
      }
    }
  }
  private onUrlChange(): void {
    // @ts-ignore
    if (window['saitag'] && window['saitag'].sendInit) {
      // @ts-ignore
      window['saitag'].sendInit();
    } else {
      setTimeout(() => this.onUrlChange(), 500);
    }
  }

  showToast() {
    if (!localStorage.getItem('privacy-accepted')) {
      this.toastActive = true;
    } else {
      // @ts-ignore
      if (typeof window['gtag'] === 'function') {
        // @ts-ignore
        window['gtag']('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted',
          'ad_personalization': 'granted',
          'ad_user_data': 'granted'
        });
      } else {
        console.error('gtag is not defined.');
      }
    }
  }


  acceptPrivacy() {
    localStorage.setItem('privacy-accepted', '1');
    this.toastActive = false;
    // @ts-ignore
    if (typeof window['gtag'] === 'function') {
      // @ts-ignore
      window['gtag']('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted',
        'ad_personalization': 'granted',
        'ad_user_data': 'granted'
      });
    } else {
      console.error('gtag is not defined.');
    }
  }

  unToggleProductsMenu(){
    this.timeoutID = setTimeout(() => {
      this.productsMenuActive = false;
    }, 300);
  }
  toggleProductsMenu(){
    clearTimeout(this.timeoutID);
    this.productsMenuActive = true;
    this.knowledgeMenuActive = false;
    this.solutionsMenuActive = false;
  }
  unToggleSolutionsMenu(){
    this.timeoutID = setTimeout(() => {
      this.solutionsMenuActive = false;
    }, 300);
  }
  toggleSolutionsMenu(){
    clearTimeout(this.timeoutID);
    this.solutionsMenuActive = true;
    this.knowledgeMenuActive = false;
    this.productsMenuActive = false;
  }
  unToggleKnowledgeMenu(){
    this.timeoutID = setTimeout(() => {
      this.knowledgeMenuActive = false;
    }, 300);
  }
  toggleKnowledgeMenu(){
    clearTimeout(this.timeoutID);
    this.solutionsMenuActive = false;
    this.knowledgeMenuActive = true;
    this.productsMenuActive = false;
  }
}
