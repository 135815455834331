import { Routes } from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {ProductsPageComponent} from './pages/products-page/products-page.component';
import {ProductListComponent} from './pages/product-list/product-list.component';
import {ProductPageComponent} from './pages/product-page/product-page.component';
import {ContactPageComponent} from './pages/contact-page/contact-page.component';

export const routes: Routes = [
  {path: '', component: HomePageComponent},
  {
    path: '',  // Pusta ścieżka – nie dodaje dodatkowego segmentu do URL
    component: ProductsPageComponent,
    children: [
      // Trasa dla kategorii – dostępna pod adresem /Category1
      {
        path: 'tasmy-termotransferowe',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      },
      {
        path: 'tekstylia',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      },
      {
        path: 'etykiety',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      },
      {
        path: 'drukarki',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      },
      {
        path: 'aplikatory',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      },
      {
        path: 'nawijaki',
        component: ProductListComponent,
        // data: { animation: 'fadeAnimation' }
      }
    ]
  },
  {path: 'tasmy-termotransferowe/:slug', component:ProductPageComponent},
  {path: 'tekstylia/:slug', component:ProductPageComponent},
  {path: 'etykiety/:slug', component:ProductPageComponent},
  {path: 'drukarki/:slug', component:ProductPageComponent},
  {path: 'aplikatory/:slug', component:ProductPageComponent},
  {path: 'nawijaki/:slug', component:ProductPageComponent},
  {path: 'kontakt', component: ContactPageComponent}
];
