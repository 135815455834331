import {Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import {BaseCecha, Tasma} from "../tasma-data/tasma-data.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {HostAddressService} from "../host-address/host-address.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {Etykieta} from "../label-data/label-data.service";
import {response} from "express";



export interface Drukarka {
  id: number,
  model: BaseCecha,
  producent: BaseCecha,
  numer_produktu: string,
  prowadzenie: BaseCecha,
  metody_wydruku: BaseCecha[],
  rodzaj_glowicy: BaseCecha,
  szerokosc_glowicy: number,
  rozdzielczosc_glowicy: number,
  maks_predkosc_druku: number,
  maks_szerokosc_wydruku: number,
  min_dlugosc_wydruku: number,
  maks_dlugosc_wydruku: number,
  szerokosc_drukarki: number,
  glebokosc_drukarki: number,
  wysokosc_drukarki: number,
  waga_drukarki: number,
  ekran: BaseCecha,
  interfejsy: BaseCecha[],
  interfejsy_dodatkowe: BaseCecha[],
  interfejsy_all: BaseCecha[],
  wlasciwosci: BaseCecha[],
  wlasciwosci_dodatkowe: BaseCecha[],
  wlasciwosci_all: BaseCecha[],
  min_szerokosc_materialu: number,
  maks_szerokosc_materialu: number,
  maks_srednica_rolki_materialu: number,
  srednice_gilz_materialu: number[],
  min_szerokosc_tasmy: number,
  maks_szerokosc_tasmy: number,
  maks_srednica_rolki_tasmy: number,
  maks_dlugosc_nawoju_tasmy: number,
  typy_nawoju: BaseCecha[],
  srednice_gilz_tasmy: number[],
  oprogramowanie: BaseCecha[],
  sterowniki: BaseCecha[],
  slug: string,
  opis: string,
  meta: string
}

interface DrukarkaRes {
  id: number,
  model: number,
  producent: number,
  numer_produktu: string,
  prowadzenie: number,
  metody_wydruku: number[],
  rodzaj_glowicy: number,
  szerokosc_glowicy: number,
  rozdzielczosc_glowicy: number,
  maks_predkosc_druku: number,
  maks_szerokosc_wydruku: number,
  min_dlugosc_wydruku: number,
  maks_dlugosc_wydruku: number,
  szerokosc_drukarki: number,
  glebokosc_drukarki: number,
  wysokosc_drukarki: number,
  waga_drukarki: number,
  ekran: number,
  interfejsy: number[],
  interfejsy_dodatkowe: number[],
  wlasciwosci: number[],
  wlasciwosci_dodatkowe: number[],
  min_szerokosc_materialu: number,
  maks_szerokosc_materialu: number,
  maks_srednica_rolki_materialu: number,
  srednice_gilz_materialu: number[],
  min_szerokosc_tasmy: number,
  maks_szerokosc_tasmy: number,
  maks_srednica_rolki_tasmy: number,
  maks_dlugosc_nawoju_tasmy: number,
  typy_nawoju: number[],
  srednice_gilz_tasmy: number[],
  oprogramowanie: number[],
  sterowniki: number[],
  slug: string,
  opis: string,
  meta: string
}

interface Res {
  drukarki: DrukarkaRes[],
  modele: BaseCecha[],
  producenci: BaseCecha[],
  prowadzenia: BaseCecha[],
  metodyWydruku: BaseCecha[],
  rodzajeGlowicy: BaseCecha[],
  ekrany: BaseCecha[],
  interfejsy: BaseCecha[],
  wlasciwosci: BaseCecha[],
  oprogramowanie: BaseCecha[],
  sterowniki: BaseCecha[],
  typyNawoju: BaseCecha[],
  szerokosciGlowicy: number[],
  rozdzielczosciGlowicy: number[],
  maksPredkosciDruku: number[],
  maksSzerokosciWydruku: number[],
  minDlugosciWydruku: number[],
  maksDlugosciWydruku: number[],
  szerokosciDrukarki: number[],
  glebokosciDrukarki: number[],
  wysokosciDrukarki: number[],
  wagiDrukarki: number[],
  minSzerokosciMaterialu: number[],
  maksSzerokosciMaterialu: number[],
  maksSredniceRolkiMaterialu: number[],
  sredniceGilzMaterialu: number[],
  minSzerokosciTasmy: number[],
  maksSzerokosciTasmy: number[],
  maksSredniceRolkiTasmy: number[],
  maksDlugosciNawojuTasmy: number[],
  sredniceGilzTasmy: number[]
}

@Injectable({
  providedIn: 'root'
})
export class PrinterDataService {
  get producenciCache(): Map<number, BaseCecha> {
    return this._producenciCache;
  }
  get sredniceGilzTasmyCache(): Map<number, number> {
    return this._sredniceGilzTasmyCache;
  }
  get typyNawojuCache(): Map<number, BaseCecha> {
    return this._typyNawojuCache;
  }
  get maksDlugosciNawojuTasmyCache(): Map<number, number> {
    return this._maksDlugosciNawojuTasmyCache;
  }
  get maksSredniceRolkiTasmyCache(): Map<number, number> {
    return this._maksSredniceRolkiTasmyCache;
  }
  get maksSzerokosciTasmyCache(): Map<number, number> {
    return this._maksSzerokosciTasmyCache;
  }
  get minSzerokosciTasmyCache(): Map<number, number> {
    return this._minSzerokosciTasmyCache;
  }
  get sredniceGilzMaterialuCache(): Map<number, number> {
    return this._sredniceGilzMaterialuCache;
  }
  get maksSredniceRolkiMaterialuCache(): Map<number, number> {
    return this._maksSredniceRolkiMaterialuCache;
  }
  get maksSzerokosciMaterialuCache(): Map<number, number> {
    return this._maksSzerokosciMaterialuCache;
  }
  get minSzerokosciMaterialuCache(): Map<number, number> {
    return this._minSzerokosciMaterialuCache;
  }
  get wagiDrukarkiCache(): Map<number, number> {
    return this._wagiDrukarkiCache;
  }
  get wysokosciDrukarkiCache(): Map<number, number> {
    return this._wysokosciDrukarkiCache;
  }
  get glebokosciDrukarkiCache(): Map<number, number> {
    return this._glebokosciDrukarkiCache;
  }
  get szerokosciDrukarkiCache(): Map<number, number> {
    return this._szerokosciDrukarkiCache;
  }
  get maksDlugosciWydrukuCache(): Map<number, number> {
    return this._maksDlugosciWydrukuCache;
  }
  get minDlugosciWydrukuCache(): Map<number, number> {
    return this._minDlugosciWydrukuCache;
  }
  get maksSzerokosciWydrukuCache(): Map<number, number> {
    return this._maksSzerokosciWydrukuCache;
  }
  get maksPredkosciDrukuCache(): Map<number, number> {
    return this._maksPredkosciDrukuCache;
  }
  get rozdzielczosciGlowicyCache(): Map<number, number> {
    return this._rozdzielczosciGlowicyCache;
  }
  get szerokosciGlowicyCache(): Map<number, number> {
    return this._szerokosciGlowicyCache;
  }
  get sterownikiCache(): Map<number, BaseCecha> {
    return this._sterownikiCache;
  }
  get oprogramowanieCache(): Map<number, BaseCecha> {
    return this._oprogramowanieCache;
  }
  get wlasciwosciCache(): Map<number, BaseCecha> {
    return this._wlasciwosciCache;
  }
  get interfejsyCache(): Map<number, BaseCecha> {
    return this._interfejsyCache;
  }
  get ekranyCache(): Map<number, BaseCecha> {
    return this._ekranyCache;
  }
  get rodzajeGlowicyCache(): Map<number, BaseCecha> {
    return this._rodzajeGlowicyCache;
  }
  get metodyWydrukuCache(): Map<number, BaseCecha> {
    return this._metodyWydrukuCache;
  }
  get prowadzeniaCache(): Map<number, BaseCecha> {
    return this._prowadzeniaCache;
  }
  get modeleCache(): Map<number, BaseCecha> {
    return this._modeleCache;
  }
  get drukarkiCache(): Map<number, Drukarka> {
    return this._drukarkiCache;
  }
  private _drukarkiCache: Map<number, Drukarka> = new Map();
  private _modeleCache: Map<number, BaseCecha> = new Map();
  private _prowadzeniaCache: Map<number, BaseCecha> = new Map();
  private _metodyWydrukuCache: Map<number, BaseCecha> = new Map();
  private _rodzajeGlowicyCache: Map<number, BaseCecha> = new Map();
  private _ekranyCache: Map<number, BaseCecha> = new Map();
  private _interfejsyCache: Map<number, BaseCecha> = new Map();
  private _wlasciwosciCache: Map<number, BaseCecha> = new Map();
  private _oprogramowanieCache: Map<number, BaseCecha> = new Map();
  private _sterownikiCache: Map<number, BaseCecha> = new Map();
  private _szerokosciGlowicyCache: Map<number, number> = new Map();
  private _rozdzielczosciGlowicyCache: Map<number, number> = new Map();
  private _maksPredkosciDrukuCache: Map<number, number> = new Map();
  private _maksSzerokosciWydrukuCache: Map<number, number> = new Map();
  private _minDlugosciWydrukuCache: Map<number, number> = new Map();
  private _maksDlugosciWydrukuCache: Map<number, number> = new Map();
  private _szerokosciDrukarkiCache: Map<number, number> = new Map();
  private _glebokosciDrukarkiCache: Map<number, number> = new Map();
  private _wysokosciDrukarkiCache: Map<number, number> = new Map();
  private _wagiDrukarkiCache: Map<number, number> = new Map();
  private _minSzerokosciMaterialuCache: Map<number, number> = new Map();
  private _maksSzerokosciMaterialuCache: Map<number, number> = new Map();
  private _maksSredniceRolkiMaterialuCache: Map<number, number> = new Map();
  private _sredniceGilzMaterialuCache: Map<number, number> = new Map();
  private _minSzerokosciTasmyCache: Map<number, number> = new Map();
  private _maksSzerokosciTasmyCache: Map<number, number> = new Map();
  private _maksSredniceRolkiTasmyCache: Map<number, number> = new Map();
  private _maksDlugosciNawojuTasmyCache: Map<number, number> = new Map();
  private _typyNawojuCache: Map<number, BaseCecha> = new Map();
  private _sredniceGilzTasmyCache: Map<number, number> = new Map();
  private _producenciCache: Map<number, BaseCecha> = new Map();

  private _dataFetched = false;

  private key: StateKey<Res> = makeStateKey("drukarkiKey");
  private isAllkey: StateKey<boolean> = makeStateKey("isAllDrukarkiKey");

  private filteredItemsSubject = new BehaviorSubject<Drukarka[]>([]);
  filteredItems$ = this.filteredItemsSubject.asObservable();

  constructor(private http: HttpClient, private  hostAddress: HostAddressService,
              private state: TransferState) { }

  fetchAllDrukarki(): Observable<void> {
    if (this._dataFetched) {
      return of();
    }
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/drukarkiAll'//, { nazwy: uncachedNazwy }
    ).pipe(
      map(response => {
        this.processResponse(response);
        this._dataFetched = true;
        return;
      })
    );
  }

  fetchDrukarki(ids: number[]): Observable<Drukarka[]> {
    const uncachedIds = ids.filter(id => ![...this._drukarkiCache.values()].some(drukarka => drukarka.id === id));
    if (uncachedIds.length === 0) {
      const cachedDrukarki = [...this._drukarkiCache.entries()]
        .filter(([id, _]) => ids.includes(id))
        .map(([_, drukarki]) => drukarki);

      return of(cachedDrukarki);
    }

    let params = new HttpParams();
    uncachedIds.forEach(id => {
      params = params.append('ids', id);
    });

    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/drukarki', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  fetchDrukarkiBySlug(slug: string): Observable<Drukarka[]>{
    for (let drukarka of this._drukarkiCache.values()) {
      if (drukarka.slug === slug) {
        return of([drukarka]);
      }
    }

    let params = new HttpParams();
    params = params.append('slug', slug);
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/drukarkiBySlug', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  processResponse(response: Res): Drukarka[] {
    response.modele.forEach(item => {
      if (!this._modeleCache.has(item.id)) {
        this._modeleCache.set(item.id, item);
      }
    });

    response.prowadzenia.forEach(item => {
      if (!this._prowadzeniaCache.has(item.id)) {
        this._prowadzeniaCache.set(item.id, item);
      }
    });

    response.metodyWydruku.forEach(item => {
      if (!this._metodyWydrukuCache.has(item.id)) {
        this._metodyWydrukuCache.set(item.id, item);
      }
    });

    response.rodzajeGlowicy.forEach(item => {
      if (!this._rodzajeGlowicyCache.has(item.id)) {
        this._rodzajeGlowicyCache.set(item.id, item);
      }
    });

    response.ekrany.forEach(item => {
      if (!this._ekranyCache.has(item.id)) {
        this._ekranyCache.set(item.id, item);
      }
    });

    response.interfejsy.forEach(item => {
      if (!this._interfejsyCache.has(item.id)) {
        this._interfejsyCache.set(item.id, item);
      }
    });

    response.wlasciwosci.forEach(item => {
      if (!this._wlasciwosciCache.has(item.id)) {
        this._wlasciwosciCache.set(item.id, item);
      }
    });

    response.oprogramowanie.forEach(item => {
      if (!this._oprogramowanieCache.has(item.id)) {
        this._oprogramowanieCache.set(item.id, item);
      }
    });

    response.sterowniki.forEach(item => {
      if (!this._sterownikiCache.has(item.id)) {
        this._sterownikiCache.set(item.id, item);
      }
    });

    response.szerokosciGlowicy.forEach(item => {
      if (!this._szerokosciGlowicyCache.has(item) && item !== null) {
        this._szerokosciGlowicyCache.set(item, item);
      }
    });

    response.rozdzielczosciGlowicy.forEach(item => {
      if (!this._rozdzielczosciGlowicyCache.has(item) && item !== null) {
        this._rozdzielczosciGlowicyCache.set(item, item);
      }
    });

    response.maksPredkosciDruku.forEach(item => {
      if (!this._maksPredkosciDrukuCache.has(item) && item !== null) {
        this._maksPredkosciDrukuCache.set(item, item);
      }
    });

    response.maksSzerokosciWydruku.forEach(item => {
      if (!this._maksSzerokosciWydrukuCache.has(item) && item !== null) {
        this._maksSzerokosciWydrukuCache.set(item, item);
      }
    });

    response.minDlugosciWydruku.forEach(item => {
      if (!this._minDlugosciWydrukuCache.has(item) && item !== null) {
        this._minDlugosciWydrukuCache.set(item, item);
      }
    });

    response.maksDlugosciWydruku.forEach(item => {
      if (!this._maksDlugosciWydrukuCache.has(item) && item !== null) {
        this._maksDlugosciWydrukuCache.set(item, item);
      }
    });

    response.szerokosciDrukarki.forEach(item => {
      if (!this._szerokosciDrukarkiCache.has(item) && item !== null) {
        this._szerokosciDrukarkiCache.set(item, item);
      }
    });

    response.glebokosciDrukarki.forEach(item => {
      if (!this._glebokosciDrukarkiCache.has(item) && item !== null) {
        this._glebokosciDrukarkiCache.set(item, item);
      }
    });

    response.wysokosciDrukarki.forEach(item => {
      if (!this._wysokosciDrukarkiCache.has(item) && item !== null) {
        this._wysokosciDrukarkiCache.set(item, item);
      }
    });

    response.wagiDrukarki.forEach(item => {
      if (!this._wagiDrukarkiCache.has(item) && item !== null) {
        this._wagiDrukarkiCache.set(item, item);
      }
    });

    response.minSzerokosciMaterialu.forEach(item => {
      if (!this._minSzerokosciMaterialuCache.has(item) && item !== null) {
        this._minSzerokosciMaterialuCache.set(item, item);
      }
    });

    response.maksSzerokosciMaterialu.forEach(item => {
      if (!this._maksSzerokosciMaterialuCache.has(item) && item !== null) {
        this._maksSzerokosciMaterialuCache.set(item, item);
      }
    });

    response.maksSredniceRolkiMaterialu.forEach(item => {
      if (!this._maksSredniceRolkiMaterialuCache.has(item) && item !== null) {
        this._maksSredniceRolkiMaterialuCache.set(item, item);
      }
    });

    response.sredniceGilzMaterialu.forEach(item => {
      if (!this._sredniceGilzMaterialuCache.has(item) && item !== null) {
        this._sredniceGilzMaterialuCache.set(item, item);
      }
    });

    response.minSzerokosciTasmy.forEach(item => {
      if (!this._minSzerokosciTasmyCache.has(item) && item !== null) {
        this._minSzerokosciTasmyCache.set(item, item);
      }
    });

    response.maksSzerokosciTasmy.forEach(item => {
      if (!this._maksSzerokosciTasmyCache.has(item) && item !== null) {
        this._maksSzerokosciTasmyCache.set(item, item);
      }
    });

    response.maksSredniceRolkiTasmy.forEach(item => {
      if (!this._maksSredniceRolkiTasmyCache.has(item) && item !== null) {
        this._maksSredniceRolkiTasmyCache.set(item, item);
      }
    });

    response.maksDlugosciNawojuTasmy.forEach(item => {
      if (!this._maksDlugosciNawojuTasmyCache.has(item) && item !== null) {
        this._maksDlugosciNawojuTasmyCache.set(item, item);
      }
    });

    response.typyNawoju.forEach(item => {
      if (!this._typyNawojuCache.has(item.id)) {
        this._typyNawojuCache.set(item.id, item);
      }
    });

    response.sredniceGilzTasmy.forEach(item => {
      if (!this._sredniceGilzTasmyCache.has(item)) {
        this._sredniceGilzTasmyCache.set(item, item);
      }
    })

    response.producenci.forEach(item => {
      if (!this._producenciCache.has(item.id)) {
        this._producenciCache.set(item.id, item);
      }
    })

    const drukarki: Drukarka[] = response.drukarki.map((drukarkiRes: DrukarkaRes) => {
      if (!this._drukarkiCache.has(drukarkiRes.model)) {
        const drukarka: Drukarka = {
          ...drukarkiRes,
          model: this._modeleCache.get(drukarkiRes.model)!,
          prowadzenie: this._prowadzeniaCache.get(drukarkiRes.prowadzenie)!,
          metody_wydruku: drukarkiRes.metody_wydruku.map(id => this._metodyWydrukuCache.get(id)!),
          rodzaj_glowicy: this._rodzajeGlowicyCache.get(drukarkiRes.rodzaj_glowicy)!,
          ekran: this._ekranyCache.get(drukarkiRes.ekran)!,
          interfejsy: drukarkiRes.interfejsy.map(id => this._interfejsyCache.get(id)!),
          interfejsy_dodatkowe: drukarkiRes.interfejsy_dodatkowe.map(id => this._interfejsyCache.get(id)!),
          interfejsy_all: [...new Set([...drukarkiRes.interfejsy,...drukarkiRes.interfejsy_dodatkowe])].map(id => this._interfejsyCache.get(id)!),
          wlasciwosci: drukarkiRes.wlasciwosci.map(id => this._wlasciwosciCache.get(id)!),
          wlasciwosci_dodatkowe: drukarkiRes.wlasciwosci_dodatkowe.map(id => this._wlasciwosciCache.get(id)!),
          wlasciwosci_all: [...new Set([...drukarkiRes.wlasciwosci,...drukarkiRes.wlasciwosci_dodatkowe])].map(id => this._wlasciwosciCache.get(id)!),
          typy_nawoju: drukarkiRes.typy_nawoju.map(id => this._typyNawojuCache.get(id)!),
          oprogramowanie: drukarkiRes.oprogramowanie.map(id => this._oprogramowanieCache.get(id)!),
          sterowniki: drukarkiRes.sterowniki.map(id => this._sterownikiCache.get(id)!),
          producent: this._producenciCache.get(drukarkiRes.producent)!
        };
        this._drukarkiCache.set(drukarkiRes.model, drukarka);
        return drukarka;
      }
      return this._drukarkiCache.get(drukarkiRes.model)!;
    }).sort((a, b) => b.producent.id - a.producent.id);
    return drukarki;
  }

  setState(drukarki: Res) {
    this.state.set(this.key, drukarki);
  }
  setStateIsAll() {
    // console.log(tasmy);
    this.state.set(this.isAllkey, true);
  }

  loadState(){
    const res = this.state.get(this.key, null);
    if (res) {
      this.processResponse(res);
    }
    this._dataFetched = this.state.get(this.isAllkey, false);
  }

  updateFilteredItems(newItems: Drukarka[]): void {
    this.filteredItemsSubject.next(newItems);
  }

  // Ewentualnie dodatkowa metoda pobierająca aktualną wartość
  getFilteredItems(): Drukarka[] {
    return this.filteredItemsSubject.getValue();
  }
}
