<div class="container">
  <div class="frame" [class.active]="active" (click)="toggleActive()">
    <div class="title">
      {{title}}
    </div>
    <div class="horizontal-line"></div>
    <div class="vertical-line" [class.active]="active"></div>
  </div>
  <div class="description" [class.active]="active">
    {{description}}
  </div>
</div>
