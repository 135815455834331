import { Component } from '@angular/core';

@Component({
  selector: 'app-drop-down-solutions',
  imports: [],
  templateUrl: './drop-down-solutions.component.html',
  standalone: true,
  styleUrl: './drop-down-solutions.component.css'
})
export class DropDownSolutionsComponent {

}
