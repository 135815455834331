<div class="app">
<!--  <app-nav-main></app-nav-main>-->

  <div class="nav-background"></div>
  <div class="nav">
    <div class="logo" routerLink="">
      <svg xmlns="http://www.w3.org/2000/svg" width="61" height="34" viewBox="0 0 61 34" fill="none">
        <path d="M60.6785 6.5918H48.3125V32.9997H60.6785V6.5918Z" fill="#2B2A29" stroke="#2B2A29" stroke-width="0.096" stroke-miterlimit="22.9256"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.317 1L46.9039 27.4874H33.7282L32.3112 24.7397H24.8861L23.4784 27.4874H22.1074L34.317 1ZM31.7317 23.3747H25.4658L28.5021 16.8986L31.7317 23.3747Z" fill="#E31E24"/>
        <path d="M46.9039 27.4874V27.5354H46.9799L46.9473 27.4668L46.9039 27.4874ZM34.317 1L34.3604 0.979398L34.3164 0.886774L34.2734 0.979906L34.317 1ZM33.7282 27.4874L33.6855 27.5094L33.6989 27.5354H33.7282V27.4874ZM32.3112 24.7397L32.3539 24.7177L32.3405 24.6917H32.3112V24.7397ZM24.8861 24.7397V24.6917H24.8568L24.8434 24.7178L24.8861 24.7397ZM23.4784 27.4874V27.5354H23.5077L23.5211 27.5093L23.4784 27.4874ZM22.1074 27.4874L22.0638 27.4673L22.0324 27.5354H22.1074V27.4874ZM25.4658 23.3747L25.4224 23.3543L25.3903 23.4227H25.4658V23.3747ZM31.7317 23.3747V23.4227H31.8092L31.7746 23.3532L31.7317 23.3747ZM28.5021 16.8986L28.5451 16.8771L28.5008 16.7883L28.4587 16.8782L28.5021 16.8986ZM46.9473 27.4668L34.3604 0.979398L34.2737 1.0206L46.8606 27.508L46.9473 27.4668ZM33.7282 27.5354H46.9039V27.4394H33.7282V27.5354ZM32.2686 24.7617L33.6855 27.5094L33.7709 27.4654L32.3539 24.7177L32.2686 24.7617ZM24.8861 24.7877H32.3112V24.6917H24.8861V24.7877ZM23.5211 27.5093L24.9288 24.7616L24.8434 24.7178L23.4356 27.4655L23.5211 27.5093ZM22.1074 27.5354H23.4784V27.4394H22.1074V27.5354ZM34.2734 0.979906L22.0638 27.4673L22.151 27.5075L34.3606 1.02009L34.2734 0.979906ZM25.4658 23.4227H31.7317V23.3267H25.4658V23.4227ZM28.4587 16.8782L25.4224 23.3543L25.5093 23.395L28.5456 16.9189L28.4587 16.8782ZM31.7746 23.3532L28.5451 16.8771L28.4592 16.92L31.6887 23.3961L31.7746 23.3532Z" fill="#E31E24"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1203 10.5616C20.7184 10.5616 20.5712 10.5616 21.2244 10.5616C20.5988 9.23634 19.8351 8.58253 18.9794 7.95525C16.716 6.48863 14.0385 6.25008 11.6187 6.28543C8.42595 6.23242 6.15331 7.03641 5.02161 7.61952C3.73347 8.50302 3.15381 9.06848 2.53736 9.94313C2.07731 10.5793 1.8749 11.0122 1.63567 11.5953C1.28603 12.4258 0.982392 14.0072 1.0008 14.8289C0.991594 17.2585 2.0037 19.432 3.16301 20.607C3.42064 20.8721 4.04631 21.623 5.06762 22.374C6.56737 23.4872 6.80659 23.6463 8.45355 24.892C10.0269 26.1466 10.5974 27.6485 10.6066 28.8412C10.579 30.9882 8.84918 31.9158 7.43225 31.907C5.79449 31.8893 4.76398 31.4299 4.04631 30.891C3.025 30.1488 2.54656 29.4774 2.06811 28.7441C1.47924 28.7529 1.83809 28.7441 1.01 28.7441C1.29523 29.4244 2.39935 30.997 3.61387 31.7127C5.38963 32.9496 7.08262 32.9937 10.809 32.9937C14.2777 32.9937 15.9799 32.658 18.363 31.156C19.283 30.3609 21.5097 28.479 21.4821 24.786C21.4821 23.6728 21.1876 21.3226 19.6603 19.5822C18.1605 17.8858 13.6613 14.8554 12.3547 13.6185C11.2875 12.6025 10.7722 12.0901 10.7078 10.6146C10.763 8.88293 12.7964 7.68137 14.6366 7.68137C15.6303 7.68137 17.1208 8.0436 18.501 8.91827C19.2646 9.46605 19.7707 10.0315 20.1203 10.5616Z" fill="#2B2A29" stroke="#2B2A29" stroke-width="0.096" stroke-miterlimit="22.9256"/>
      </svg>
    </div>
    <div class="menu">
      <button class="ButtonMenu" (mouseenter)="toggleProductsMenu()" (mouseleave)="unToggleProductsMenu()">Produkty</button>
      <button class="ButtonMenu" (mouseenter)="toggleSolutionsMenu()" (mouseleave)="unToggleSolutionsMenu()">Zastosowania</button>
      <button class="ButtonMenu">O nas</button>
      <button class="ButtonMenu" (mouseenter)="toggleKnowledgeMenu()" (mouseleave)="unToggleKnowledgeMenu()">Wiedza</button>
      <div class="contact-button">
        <button class="ButtonMenu Outline">Napisz do nas</button>
      </div>
    </div>
    <app-drop-down-products (mouseenter)="toggleProductsMenu()" (mouseleave)="unToggleProductsMenu()" [class.active]="productsMenuActive"></app-drop-down-products>
    <app-drop-down-solutions (mouseenter)="toggleSolutionsMenu()" (mouseleave)="unToggleSolutionsMenu()" [class.active]="solutionsMenuActive"></app-drop-down-solutions>
    <app-drop-down-knowledge (mouseenter)="toggleKnowledgeMenu()" (mouseleave)="unToggleKnowledgeMenu()" [class.active]="knowledgeMenuActive"></app-drop-down-knowledge>
  </div>
  <div class="page">
    <div class="margin left"></div>
    <div class="margin right"></div>
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <div class="frame1">
      <div class="logo" routerLink="">
        <svg xmlns="http://www.w3.org/2000/svg" width="61" height="34" viewBox="0 0 61 34" fill="none">
          <path d="M60.6785 6.59229H48.3125V33.0002H60.6785V6.59229Z" fill="#2B2A29" stroke="#2B2A29" stroke-width="0.096" stroke-miterlimit="22.9256"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.317 1L46.9039 27.4874H33.7282L32.3112 24.7397H24.8861L23.4784 27.4874H22.1074L34.317 1ZM31.7307 23.3745H25.4648L28.5012 16.8984L31.7307 23.3745Z" fill="#E31E24"/>
          <path d="M46.9039 27.4874V27.5354H46.9799L46.9473 27.4668L46.9039 27.4874ZM34.317 1L34.3604 0.979398L34.3164 0.886774L34.2734 0.979906L34.317 1ZM33.7282 27.4874L33.6855 27.5094L33.6989 27.5354H33.7282V27.4874ZM32.3112 24.7397L32.3539 24.7177L32.3405 24.6917H32.3112V24.7397ZM24.8861 24.7397V24.6917H24.8568L24.8434 24.7178L24.8861 24.7397ZM23.4784 27.4874V27.5354H23.5077L23.5211 27.5093L23.4784 27.4874ZM22.1074 27.4874L22.0638 27.4673L22.0324 27.5354H22.1074V27.4874ZM25.4648 23.3745L25.4214 23.3542L25.3893 23.4225H25.4648V23.3745ZM31.7307 23.3745V23.4225H31.8083L31.7736 23.3531L31.7307 23.3745ZM28.5012 16.8984L28.5441 16.877L28.4998 16.7882L28.4577 16.8781L28.5012 16.8984ZM46.9473 27.4668L34.3604 0.979398L34.2737 1.0206L46.8606 27.508L46.9473 27.4668ZM33.7282 27.5354H46.9039V27.4394H33.7282V27.5354ZM32.2686 24.7617L33.6855 27.5094L33.7709 27.4654L32.3539 24.7177L32.2686 24.7617ZM24.8861 24.7877H32.3112V24.6917H24.8861V24.7877ZM23.5211 27.5093L24.9288 24.7616L24.8434 24.7178L23.4356 27.4655L23.5211 27.5093ZM22.1074 27.5354H23.4784V27.4394H22.1074V27.5354ZM34.2734 0.979906L22.0638 27.4673L22.151 27.5075L34.3606 1.02009L34.2734 0.979906ZM25.4648 23.4225H31.7307V23.3265H25.4648V23.4225ZM28.4577 16.8781L25.4214 23.3542L25.5083 23.3949L28.5446 16.9188L28.4577 16.8781ZM31.7736 23.3531L28.5441 16.877L28.4582 16.9199L31.6877 23.396L31.7736 23.3531Z" fill="#E31E24"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1203 10.5621C20.7184 10.5621 20.5712 10.5621 21.2244 10.5621C20.5988 9.23682 19.8351 8.58302 18.9794 7.95573C16.716 6.48912 14.0385 6.25057 11.6187 6.28591C8.42595 6.23291 6.15331 7.03689 5.02161 7.62001C3.73347 8.50351 3.15381 9.06896 2.53736 9.94362C2.07731 10.5797 1.8749 11.0127 1.63567 11.5958C1.28603 12.4263 0.982392 14.0077 1.0008 14.8294C0.991594 17.259 2.0037 19.4324 3.16301 20.6075C3.42064 20.8726 4.04631 21.6235 5.06762 22.3745C6.56737 23.4877 6.80659 23.6468 8.45355 24.8925C10.0269 26.1471 10.5974 27.649 10.6066 28.8417C10.579 30.9887 8.84918 31.9163 7.43225 31.9075C5.79449 31.8898 4.76398 31.4304 4.04631 30.8915C3.025 30.1493 2.54656 29.4779 2.06811 28.7446C1.47924 28.7534 1.83809 28.7446 1.01 28.7446C1.29523 29.4249 2.39935 30.9975 3.61387 31.7131C5.38963 32.95 7.08262 32.9942 10.809 32.9942C14.2777 32.9942 15.9799 32.6585 18.363 31.1565C19.283 30.3614 21.5097 28.4795 21.4821 24.7865C21.4821 23.6733 21.1876 21.3231 19.6603 19.5826C18.1605 17.8863 13.6613 14.8559 12.3547 13.619C11.2875 12.603 10.7722 12.0905 10.7078 10.6151C10.763 8.88342 12.7964 7.68186 14.6366 7.68186C15.6303 7.68186 17.1208 8.04409 18.501 8.91876C19.2646 9.46653 19.7707 10.032 20.1203 10.5621Z" fill="#2B2A29" stroke="#2B2A29" stroke-width="0.096" stroke-miterlimit="22.9256"/>
        </svg>
      </div>
      <div class="text">Twój partner w efektywnej<br>identyfikacji od 1993 roku.</div>
      <div class="social-frame">
        <a class="SocialButtons" href="">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.25 0H5.25C3.85807 0.00148896 2.52358 0.555091 1.53933 1.53933C0.555091 2.52358 0.00148896 3.85807 0 5.25V14.25C0.00148896 15.6419 0.555091 16.9764 1.53933 17.9607C2.52358 18.9449 3.85807 19.4985 5.25 19.5H14.25C15.6419 19.4985 16.9764 18.9449 17.9607 17.9607C18.9449 16.9764 19.4985 15.6419 19.5 14.25V5.25C19.4985 3.85807 18.9449 2.52358 17.9607 1.53933C16.9764 0.555091 15.6419 0.00148896 14.25 0ZM9.75 14.25C8.85998 14.25 7.98995 13.9861 7.24993 13.4916C6.50991 12.9971 5.93314 12.2943 5.59254 11.4721C5.25195 10.6498 5.16283 9.74501 5.33647 8.87209C5.5101 7.99918 5.93868 7.19736 6.56802 6.56802C7.19736 5.93868 7.99918 5.5101 8.87209 5.33647C9.74501 5.16283 10.6498 5.25195 11.4721 5.59254C12.2943 5.93314 12.9971 6.50991 13.4916 7.24993C13.9861 7.98995 14.25 8.85998 14.25 9.75C14.2488 10.9431 13.7743 12.087 12.9306 12.9306C12.087 13.7743 10.9431 14.2488 9.75 14.25ZM15.375 5.25C15.1525 5.25 14.935 5.18402 14.75 5.0604C14.565 4.93679 14.4208 4.76109 14.3356 4.55552C14.2505 4.34995 14.2282 4.12375 14.2716 3.90552C14.315 3.68729 14.4222 3.48684 14.5795 3.3295C14.7368 3.17217 14.9373 3.06502 15.1555 3.02162C15.3738 2.97821 15.6 3.00049 15.8055 3.08564C16.0111 3.17078 16.1868 3.31498 16.3104 3.49998C16.434 3.68499 16.5 3.9025 16.5 4.125C16.5 4.42337 16.3815 4.70952 16.1705 4.9205C15.9595 5.13147 15.6734 5.25 15.375 5.25ZM12.75 9.75C12.75 10.3433 12.5741 10.9234 12.2444 11.4167C11.9148 11.9101 11.4462 12.2946 10.8981 12.5216C10.3499 12.7487 9.74667 12.8081 9.16473 12.6924C8.58279 12.5766 8.04824 12.2909 7.62868 11.8713C7.20912 11.4518 6.9234 10.9172 6.80764 10.3353C6.69189 9.75333 6.7513 9.15013 6.97836 8.60195C7.20542 8.05377 7.58994 7.58524 8.08329 7.25559C8.57664 6.92595 9.15666 6.75 9.75 6.75C10.5456 6.75 11.3087 7.06607 11.8713 7.62868C12.4339 8.19129 12.75 8.95435 12.75 9.75Z" fill="black" fill-opacity="0.6"/>
          </svg>
        </a>
        <a class="SocialButtons" href="">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M20 9.78216C19.9969 12.1732 19.1226 14.4807 17.5418 16.2698C15.9609 18.059 13.7826 19.2064 11.4172 19.4958C11.3645 19.5018 11.3112 19.4965 11.2607 19.4803C11.2102 19.4641 11.1637 19.4373 11.1243 19.4018C11.0849 19.3662 11.0534 19.3227 11.032 19.274C11.0105 19.2254 10.9996 19.1727 11 19.1196V12.0398H13.25C13.3528 12.0401 13.4546 12.0191 13.549 11.9782C13.6433 11.9373 13.7284 11.8773 13.7987 11.8021C13.869 11.7268 13.9232 11.6379 13.9578 11.5408C13.9924 11.4436 14.0068 11.3404 14 11.2374C13.9834 11.0439 13.8944 10.8639 13.751 10.7335C13.6075 10.6031 13.4201 10.5321 13.2265 10.5347H11V8.27704C11 7.87786 11.158 7.49502 11.4393 7.21276C11.7206 6.93049 12.1022 6.77192 12.5 6.77192H14C14.1028 6.77215 14.2046 6.75116 14.299 6.71026C14.3934 6.66936 14.4784 6.60942 14.5487 6.53417C14.619 6.45892 14.6732 6.36997 14.7078 6.27283C14.7424 6.17569 14.7568 6.07244 14.75 5.9695C14.7334 5.77565 14.6442 5.59533 14.5003 5.46492C14.3564 5.3345 14.1685 5.2637 13.9747 5.2668H12.5C11.7043 5.2668 10.9413 5.58395 10.3787 6.14848C9.81605 6.71301 9.49998 7.47867 9.49998 8.27704V10.5347H7.24997C7.14716 10.5345 7.04539 10.5555 6.951 10.5964C6.85661 10.6373 6.77161 10.6972 6.70127 10.7725C6.63094 10.8477 6.57677 10.9367 6.54214 11.0338C6.50751 11.131 6.49316 11.2342 6.49997 11.3371C6.51656 11.531 6.6058 11.7113 6.74969 11.8417C6.89358 11.9721 7.08141 12.0429 7.27528 12.0398H9.49998V19.1214C9.5003 19.1745 9.48942 19.2271 9.46805 19.2757C9.44668 19.3243 9.4153 19.3677 9.37598 19.4033C9.33667 19.4388 9.2903 19.4656 9.23993 19.4819C9.18956 19.4982 9.13632 19.5036 9.08373 19.4977C6.65499 19.2009 4.42581 18 2.83741 16.1327C1.24901 14.2654 0.417161 11.8677 0.50652 9.41435C0.69402 4.33456 4.79465 0.204887 9.86092 0.0073401C11.1726 -0.0436402 12.481 0.171352 13.7079 0.639458C14.9349 1.10756 16.0551 1.81916 17.0016 2.7317C17.9482 3.64423 18.7016 4.73895 19.2168 5.95038C19.7319 7.16181 19.9983 8.46505 20 9.78216Z" fill="black" fill-opacity="0.6"/>
          </svg>
        </a>
      </div>
    </div>
    <div class="frame2">
      <div class="frame3">
        <div class="title">Kontakt</div>
        <div class="text">ul. Sosnowa 94/98<br>97-200<br>Tomaszów Mazowiecki</div>
        <a class="FooterLink" href="mailto:kontakt@sai.pl">kontakt&#64;sai.pl</a>
        <a class="FooterLink" href="tel:+48521060571">+48 531 060 571</a>
      </div>
      <div class="frame3">
        <div class="title">Produkty</div>
        <a class="FooterLink" href="">Taśmy termotransferowe</a>
        <a class="FooterLink" href="">Materiały tekstylne</a>
        <a class="FooterLink" href="">Etykiety</a>
        <a class="FooterLink" href="">Drukarki</a>
        <a class="FooterLink" href="">Nawijaki</a>
        <a class="FooterLink" href="">Aplikatory</a>
      </div>
      <div class="frame3">
        <div class="title">SAI</div>
        <a class="FooterLink" href="">O nas</a>
        <a class="FooterLink" href="">Kariera</a>
        <a class="FooterLink" href="">Wiedza</a>
        <a class="FooterLink" href="">FAQ</a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="toastActive" class="toast">
  <p style="color: black">
    Używamy plików cookies na naszej stronie internetowej. Kontynuując korzystanie z naszej strony internetowej, bez zmiany ustawień prywatności przeglądarki, wyrażasz zgodę na przetwarzanie Twoich danych osobowych takich jak adres IP czy identyfikatory plików cookies w celach analitycznych i statystycznych. Więcej informacji znajdziesz w naszej <a style="color: #000000" href="/polityka-prywatnosci/">polityce prywatności.</a>
  </p>
  <div class="button-style" (click)="acceptPrivacy()">Akceptuj</div>
</div>
<!--<div class="enquiry-window" style="width: 100%">-->
<!--  <app-enquiry-window style="width: 100%"></app-enquiry-window>-->
<!--</div>-->
