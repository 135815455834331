<div class="page">
  <div class="EnquiryWindow">
    <div class="ContactDetails">
      <div class="frame1">
        <div class="frame">
        <div class="text1">Skontaktuj się z nami</div>
        <div class="text2">Nasi specjaliści pomogą Ci znaleźć najlepsze rozwiązania dopasowane do Twoich potrzeb.</div>
        </div>
      </div>
      <div class="frame2">
        <a href="mailto:kontakt@sai.pl" class="contact-text">kontakt&#64;sai.pl</a>
        <a href="tel:+48 531 060 571" class="contact-text">+48 531 060 571</a>
        <div class="info-text">ul. Sosnowa 94/98<br>97-200 Tomaszów Mazowiecki</div>
      </div>
    </div>
    <div class="accordionLine"></div>
    <form [formGroup]="form">
      <div class="ContactForm">
        <div class="text-form-container">
          <div class="title-frame">
            <div class="title">Imię i Nazwisko</div>
          </div>
          <input formControlName="imie" class="form-frame"/>
        </div>
        <div class="text-form-container">
          <div class="title-frame">
            <div class="title">Firma</div>
          </div>
          <input formControlName="firma" class="form-frame"/>
        </div>
        <div class="text-form-container">
          <div class="title-frame">
            <div class="title">Adres e-mail</div>
          </div>
          <input formControlName="mail" class="form-frame"/>
        </div>
        <div class="text-form-container">
          <div class="title-frame">
            <div class="title">
              Numer telefonu
            </div>
            <div class="sub-frame">
              opcjonalne
            </div>
          </div>
          <input formControlName="tel" class="form-frame"/>
        </div>
        <div class="text-form-container">
          <div class="title-frame">
            <div class="title">Wiadomość</div>
          </div>
          <input formControlName="wiadomosc" class="form-frame tall"/>
        </div>
        <div class="zgoda">
          <input formControlName="consent" type="checkbox" class="rectangle"
                 [class.error]="submitted && form.get('consent')?.invalid"/>
          <div class="text">Zgadzam się na przetwarzanie moich danych osobowych w celach informacyjnych i marketingowych, opisanych w Polityce Prywatności. Zobacz: Politykę Prywatności oraz Regulamin.</div>
        </div>
        <div class="ButtonFlat Red" (click)="onSubmit()">
          Wyślij zapytanie
        </div>
      </div>
    </form>
  </div>
  <div class="accordionLine"></div>
  <div class="FAQSection">
    <div class="leftContainer">
      <div class="Label"><div class="text">FAQ</div></div>
      <div class="title">Poznaj odpowiedzi na <br>najczęstsze <span class="decoration">pytania</span></div>
      <a class="ButtonSecondaryCTA Red"><div class="Text">Masz inne pytania?</div></a>
    </div>
    <div class="rightContainer">
      <div class="accordion-line"></div>
      <div class="element" *ngFor="let element of FAQ_items; let i = index">
        <app-unwind-description [title]="element.title" [description]="element.description" [active]="FAQs[i]" (toggled)="untoggleFAQs($event, i)"></app-unwind-description>
        <div class="accordion-line"></div>
      </div>
    </div>
  </div>
</div>
