<div class="product-list-line"></div>
<div *ngIf="active_category === 0" class="product-list">
  <div *ngFor="let product of filteredTasmy">
    <app-tape-card class="product-card" [produkt]="product"></app-tape-card>
<!--    Taśma-->
    <div class="product-list-line"></div>
  </div>
</div>
<div *ngIf="active_category === 1" class="product-list">
  <div *ngFor="let product of filteredTekstylia">
    <app-textile-card class="product-card" [produkt]="product"></app-textile-card>
<!--    Tesktylio-->
    <div class="product-list-line"></div>
  </div>
</div>
<div *ngIf="active_category === 2" class="product-list">
  <div *ngFor="let product of filteredEtykiety">
    <app-label-card class="product-card" [produkt]="product"></app-label-card>
<!--    etykieto-->
    <div class="product-list-line"></div>
  </div>
</div>
<div *ngIf="active_category === 3" class="product-list">
  <div *ngFor="let product of filteredDrukarki">
    <app-printer-card class="product-card" [produkt]="product"></app-printer-card>
<!--    drukarko-->
    <div class="product-list-line"></div>
  </div>
</div>
<div *ngIf="active_category === 4" class="product-list">
  <div *ngFor="let product of filteredAplikatory">
    <app-aplikator-card class="product-card" [produkt]="product"></app-aplikator-card>
    <!--    drukarko-->
    <div class="product-list-line"></div>
  </div>
</div>
