import {Component, Input} from '@angular/core';
import {Etykieta} from '../../services/label-data/label-data.service';

@Component({
  selector: 'app-label-card',
    imports: [
    ],
  templateUrl: './label-card.component.html',
  standalone: true,
  styleUrl: './label-card.component.css'
})
export class LabelCardComponent {
  @Input() produkt: Etykieta = {
    id: 0,
    name: '',
    material_wierzchni: {
      id: 0,
      nazwa: ''
    },
    druk: {
      id: 0,
      nazwa: ''
    },
    powierzchnia: {
      id: 0,
      nazwa: ''
    },
    klej: {
      id: 0,
      nazwa: ''
    },
    kolory: [],
    grubosc_materialu_wierzchniego: 0,
    gramatura_materialu_wierzchniego: 0,
    grubosc_podkladu: 0,
    grubosc_calkowita: 0,
    przyczepnosc: 0,
    minimalna_temperatura_aplikacji: 0,
    minimalna_temperatura_uzytkowania: 0,
    maksymalna_temperatura_uzytkowania: 0,
    przeznaczenia: [],
    wlasciwosci: [],
    okres_aplikacji: {
      id: 0,
      nazwa: ''
    },
    powierzchnie_aplikacji: [],
    polecane_tasmy: [],
    slug: '',
    opis: '',
    meta: '',
    name_long: ''
  }

  concatenateNazwa(objects: { nazwa: string }[]): string {
    return objects.map(obj => obj.nazwa).join(', ');
  }
}
