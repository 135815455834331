import {AfterViewInit, Component, ElementRef, ViewChild, HostListener, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Textile, TextileDataService} from '../../services/textile-data/textile-data.service';
import {Etykieta, LabelDataService} from '../../services/label-data/label-data.service';
import {Drukarka, PrinterDataService} from '../../services/printer-data/printer-data.service';
import {Tasma, TasmaDataService} from '../../services/tasma-data/tasma-data.service';
import {Meta, Title} from '@angular/platform-browser';
import {EnquiryWindowComponent} from '../../components/enquiry-window/enquiry-window.component';

@Component({
  selector: 'app-product-page',
  imports: [
    NgIf,
    NgForOf,
    NgOptimizedImage,
    EnquiryWindowComponent
  ],
  templateUrl: './product-page.component.html',
  standalone: true,
  styleUrl: './product-page.component.css'
})
export class ProductPageComponent implements OnInit, AfterViewInit{
  @ViewChild('sticky') stickyRef!: ElementRef<HTMLDivElement>;
  @ViewChild('parent') parentRef!: ElementRef<HTMLDivElement>;

  description_exapnded = false;
  header = 'tasmy';
  title = 'B201';
  description = 'Materiał z kategorii premium przeznaczony do wytwarzania wszywek odzieżowych. W odróżnieniu od nylonu jest niezwykle miękki, dzięki czemu nie nie przeszkadza w użytkowaniu';
  params_active = false;
  zastosowania_active = false;
  category = 0;
  params = [{type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 0, name: 'Materiał', value: '100% poliester', last: false},
    {type: 1, name: 'Metody zadruku', values: ['termotransfer', 'sitodruk', 'sublimacja', 'hot stamping'], last: true}];
  enquiryActive = false;

  zastosowania = [{type: 0, values: ['etykiety logistyczne', 'etykiety ostrzegawcze']}];

  slug: string = '';
  active_category = 0;

  tasma: Tasma = {
    id: 0,
    nazwa: '',
    kolory: [],
    glowice: [],
    typ: {
      id: 0,
      nazwa: ''
    },
    szybkosc_zadruku: 0,
    odpornosc_temperaturowa: 0,
    odpornosc_mechaniczna: 0,
    odpornosc_chemiczna: 0,
    czulosc: 0,
    opis: '',
    zastosowania: [],
    materialy: [],
    img: '',
    display_type: 0,
    display_spec: '',
    slug: '',
    meta: ''
  };
  textile: Textile = {
    id: 0,
    nazwa: '',
    nazwa_long: '',
    kolory: [],
    rodzaj_materialu: {
      id: 0,
      nazwa: ''
    },
    struktura: {
      id: 0,
      nazwa: ''
    },
    grubosc: 0,
    gramatura: 0,
    optymalna_predkosc_zadruku: 0,
    maksymalna_predkosc_zadruku: 0,
    predkosc_zadruku_skala: 0,
    sztywnosc: 0,
    odpornosc_na_pranie: 0,
    material_niestrzepiacy: false,
    metody_zadruku: [],
    zadruk: {
      id: 0,
      nazwa: ''
    },
    charakterystyka: '',
    opis: '',
    pranie: [],
    prasowanie: 0,
    przeznaczenia: [],
    zastosowania: [],
    polecane_tasmy: [],
    slug: '',
    tasmy: [],
    meta: ''
  };
  label: Etykieta = {
    id: 0,
    name: '',
    material_wierzchni: {
      id: 0,
      nazwa: ''
    },
    druk: {
      id: 0,
      nazwa: ''
    },
    powierzchnia: {
      id: 0,
      nazwa: ''
    },
    klej: {
      id: 0,
      nazwa: ''
    },
    kolory: [],
    grubosc_materialu_wierzchniego: 0,
    gramatura_materialu_wierzchniego: 0,
    grubosc_podkladu: 0,
    grubosc_calkowita: 0,
    przyczepnosc: 0,
    minimalna_temperatura_aplikacji: 0,
    minimalna_temperatura_uzytkowania: 0,
    maksymalna_temperatura_uzytkowania: 0,
    przeznaczenia: [],
    wlasciwosci: [],
    okres_aplikacji: {
      id: 0,
      nazwa: ''
    },
    powierzchnie_aplikacji: [],
    polecane_tasmy: [],
    slug: '',
    opis: '',
    meta: '',
    name_long: ''
  };
  printer: Drukarka = {
    id: 0,
    model: {
      id: 0,
      nazwa: ''
    },
    producent: {
      id: 0,
      nazwa: ''
    },
    numer_produktu: '',
    prowadzenie: {
      id: 0,
      nazwa: ''
    },
    metody_wydruku: [],
    rodzaj_glowicy: {
      id: 0,
      nazwa: ''
    },
    szerokosc_glowicy: 0,
    rozdzielczosc_glowicy: 0,
    maks_predkosc_druku: 0,
    maks_szerokosc_wydruku: 0,
    min_dlugosc_wydruku: 0,
    maks_dlugosc_wydruku: 0,
    szerokosc_drukarki: 0,
    glebokosc_drukarki: 0,
    wysokosc_drukarki: 0,
    waga_drukarki: 0,
    ekran: {
      id: 0,
      nazwa: ''
    },
    interfejsy: [],
    interfejsy_dodatkowe: [],
    interfejsy_all: [],
    wlasciwosci: [],
    wlasciwosci_dodatkowe: [],
    wlasciwosci_all: [],
    min_szerokosc_materialu: 0,
    maks_szerokosc_materialu: 0,
    maks_srednica_rolki_materialu: 0,
    srednice_gilz_materialu: [],
    min_szerokosc_tasmy: 0,
    maks_szerokosc_tasmy: 0,
    maks_srednica_rolki_tasmy: 0,
    maks_dlugosc_nawoju_tasmy: 0,
    typy_nawoju: [],
    srednice_gilz_tasmy: [],
    oprogramowanie: [],
    sterowniki: [],
    slug: '',
    opis: '',
    meta: ''
  };


  constructor(private route: ActivatedRoute,
              private router: Router,
              private metaService: Meta,
              private titleService: Title,
              private tasmaDataService: TasmaDataService,
              private textileService: TextileDataService,
              private labelService: LabelDataService,
              private printerService: PrinterDataService) {
  }

  ngOnInit(){
    this.setCategoryOnSlug();
  }

  setCategoryOnSlug(){
    // if (this.route.snapshot.routeConfig && 'path' in this.route.snapshot.routeConfig) {
    //   this.slug = this.route.snapshot.routeConfig.path || null;
    // }
    if (this.router.url){
      this.slug = this.router.url;
    }
    let url_params = this.slug.split('/', 3);
    if (url_params[1] == 'tasmy-termotransferowe') {
      this.active_category = 0;
    } else if (url_params[1] == 'tekstylia') {
      this.active_category = 1;
    } else if (url_params[1] == 'etykiety') {
      this.active_category = 2;
    } else if (url_params[1] == 'drukarki') {
      this.active_category = 3;
    } else if (url_params[1] == 'aplikatory') {
      this.active_category = 4;
    } else if (url_params[1] == 'nawijaki') {
      this.active_category = 5;
    }
    this.slug = url_params[2]
    this.loadData();
  }

  loadData(){
    switch (this.active_category) {
      case 0:
        this.tasmaDataService.fetchTasmaBySlug(this.slug).subscribe(tasmy => {
          this.tasma = tasmy[0];
          this.titleService.setTitle('Taśma termotransferowa ' + this.tasma.nazwa);
          this.metaService.updateTag(
            { name: 'description', content: this.tasma.meta }
          );
          this.header = 'Taśmy termotransferowe';
          this.title = this.tasma.nazwa;
          this.description = this.tasma.opis;
          let values: string[] = [];
          this.tasma.materialy.forEach(value => {values = values.concat(value.nazwa)});
          // console.log(values);
          this.params = [{type: 0, name: 'Do głowicy', value: this.concatenateNazwa(this.tasma.glowice), last: false},
            {type: 0, name: 'Maksymalna prędkość zadruku [mm/s]', value: this.tasma.szybkosc_zadruku.toString(), last: false},
            {type: 0, name: 'Czułość [maks. 10]', value: this.tasma.czulosc.toString(), last: false},
            {type: 0, name: 'Odporność na ścieranie [maks. 10]', value: this.tasma.odpornosc_mechaniczna.toString(), last: false},
            {type: 0, name: 'Odporność temperaturowa [°C]', value: this.tasma.odpornosc_temperaturowa.toString(), last: false},
            {type: 0, name: 'Odporność chemiczna [maks. 10]', value: this.tasma.odpornosc_chemiczna.toString(), last: false},
            {type: 1, name: 'Zadrukowywane materiały', values: values , last: true},];
          values = [];
          this.tasma.zastosowania.forEach(value => {values = values.concat(value.nazwa)});
          this.zastosowania = [{type: 0, values: values}];
        });
        break;
      case 1:
        this.textileService.fetchTekstyliaBySlug(this.slug).subscribe(tekstylia => {
          this.textile = tekstylia[0];
          this.titleService.setTitle(this.textile.rodzaj_materialu.nazwa + ' ' + this.textile.nazwa);
          this.metaService.updateTag(
            { name: 'description', content: this.textile.meta }
          );
        });
        break;
      case 2:
        this.labelService.fetchEtykietyBySlug(this.slug).subscribe(labels => {
          this.label = labels[0];
          this.titleService.setTitle(this.label.material_wierzchni.nazwa + ' ' + this.label.name);
          this.metaService.updateTag(
            { name: 'description', content: this.label.meta }
          );
        });
        break;
      case 3:
        this.printerService.fetchDrukarkiBySlug(this.slug).subscribe(printers => {
          this.printer = printers[0];
          this.titleService.setTitle(this.printer.producent.nazwa + ' ' + this.printer.model.nazwa);
          this.metaService.updateTag(
            { name: 'description', content: this.printer.meta }
          );
        });
        break;
      case 4:
        break;
      case 6:
        break;
    }
  }

  ngAfterViewInit() {
    // this.setupResizeObserver();
    this.updateStickyPosition(); // Początkowa aktualizacja
  }

  @HostListener('window:scroll')
  onScroll() {
    requestAnimationFrame(() => this.updateStickyPosition());
  }

  private updateStickyPosition() {
    const sticky = this.stickyRef.nativeElement;
    const parent = this.parentRef.nativeElement;

    const stickyHeight = sticky.offsetHeight;
    const viewportHeight = window.innerHeight;
    const parentRect = parent.getBoundingClientRect();
    const scrollY = -parentRect.top;
    const difference = stickyHeight - viewportHeight;

    // Oblicz pozycję, gdzie dolna granica sticky dotyka dołu viewportu
    const stickyBottomAtViewport = scrollY + viewportHeight - stickyHeight;

    // console.log('stickyHeight:', stickyHeight, ', viewportHeight: ', viewportHeight, ', scrollY:', scrollY, ', stickyBottomAtViewport:', stickyBottomAtViewport, ', top:', parentRect.top);
    if(scrollY >= 0) {
      if (stickyHeight <= viewportHeight) {
        // Jeśli sticky jest mniejszy niż viewport, użyj zwykłego sticky
        sticky.style.position = 'sticky';
        sticky.style.top = `0`;
        sticky.style.transform = 'none';
      } else if (scrollY <= 0) {
        // Na samej górze strony
        sticky.style.position = 'relative';
        sticky.style.top = '0';
        sticky.style.transform = 'none';
      } else if (scrollY >= difference) {
        // Gdy dolna granica sticky dotarła do dołu viewportu
        sticky.style.position = 'sticky';
        sticky.style.top = `-${difference}px`;
        sticky.style.transform = 'none';
      } else {
        // Podczas scrollowania, zanim dolna granica dotrze do dołu
        sticky.style.position = 'relative';
        sticky.style.top = `0px`;
        sticky.style.transform = `none`;
      }
    }
  }

  concatenateNazwa(objects: { nazwa: string }[]): string {
    return objects.map(obj => obj.nazwa).join(', ');
  }

  toggleEnquiry(){
    this.enquiryActive = !this.enquiryActive;
  }

  protected readonly encodeURIComponent = encodeURIComponent;
}
