import {Injectable, makeStateKey, StateKey, TransferState} from '@angular/core';
import {BaseCecha, Tasma} from "../tasma-data/tasma-data.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {HostAddressService} from "../host-address/host-address.service";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import {response} from 'express';

export interface Textile {
  id: number,
  nazwa: string,
  nazwa_long: string,
  kolory: TextileKoloryCecha[],
  rodzaj_materialu: BaseCecha,
  struktura: BaseCecha,
  grubosc: number,
  gramatura: number,
  optymalna_predkosc_zadruku: number,
  maksymalna_predkosc_zadruku: number,
  predkosc_zadruku_skala: number,
  sztywnosc: number,
  odpornosc_na_pranie: number,
  material_niestrzepiacy: boolean,
  metody_zadruku: BaseCecha[],
  zadruk: BaseCecha,
  charakterystyka: string,
  opis: string,
  pranie: BaseCecha[],
  prasowanie: number,
  przeznaczenia: BaseCecha[],
  zastosowania: BaseCecha[],
  polecane_tasmy: number[],
  slug: string,
  tasmy: number[],
  meta: string
}

export interface TextileKoloryCecha extends BaseCecha {
  pantone: string
}

interface TextileRes {
  id: number,
  nazwa: string,
  nazwa_long: string,
  kolory: number[],
  rodzaj_materialu: number,
  struktura: number,
  grubosc: number,
  gramatura: number,
  optymalna_predkosc_zadruku: number,
  maksymalna_predkosc_zadruku: number,
  predkosc_zadruku_skala: number,
  sztywnosc: number,
  odpornosc_na_pranie: number,
  material_niestrzepiacy: boolean,
  metody_zadruku: number[],
  zadruk: number,
  charakterystyka: string,
  opis: string,
  pranie: number[],
  prasowanie: number,
  przeznaczenia: number[],
  zastosowania: number[],
  polecane_tasmy: number[],
  slug: string,
  tasmy: number[],
  meta: string
}
interface Res {
  tekstylia: TextileRes[],
  kolory: TextileKoloryCecha[],
  zastosowania: BaseCecha[],
  materialy: BaseCecha[],
  grubosci: number[],
  gramatury: number[],
  maksymalne_predkosci_zadruku: number[],
  sztywnosci: number[],
  metody_zadruku: BaseCecha[],
  prania: BaseCecha[],
  przeznaczenia: BaseCecha[],
  zadruki: BaseCecha[],
  struktury: BaseCecha[]
}

@Injectable({
  providedIn: 'root'
})
export class TextileDataService {
  get strukturyCache(): Map<number, BaseCecha> {
    return this._strukturyCache;
  }
  // get tasmyCache(): Map<number, Tasma> {
  //   return this._tasmyCache;
  // }
  get tekstyliaCache(): Map<number, Textile> {
    return this._tekstyliaCache;
  }
  get koloryCache(): Map<number, TextileKoloryCecha> {
    return this._koloryCache;
  }
  get zastosowaniaCache(): Map<number, BaseCecha> {
    return this._zastosowaniaCache;
  }
  get materialyCache(): Map<number, BaseCecha> {
    return this._materialyCache;
  }
  get grubosciCache(): Map<number, number> {
    return this._grubosciCache;
  }
  get gramaturyCache(): Map<number, number> {
    return this._gramaturyCache;
  }
  get maksymalne_predkosci_zadrukuCache(): Map<number, number> {
    return this._maksymalne_predkosci_zadrukuCache;
  }
  get sztywnosciCache(): Map<number, number> {
    return this._sztywnosciCache;
  }
  get metody_zadrukuCache(): Map<number, BaseCecha> {
    return this._metody_zadrukuCache;
  }
  get praniaCache(): Map<number, BaseCecha> {
    return this._praniaCache;
  }
  get przeznaczeniaCache(): Map<number, BaseCecha> {
    return this._przeznaczeniaCache;
  }
  get zadrukiCache(): Map<number, BaseCecha> {
    return this._zadrukiCache;
  }
  // get dataFetched(): boolean {
  //   return this._dataFetched;
  // }
  //
  // set dataFetched(value: boolean) {
  //   this._dataFetched = value;
  // }
  // private _tasmyCache: Map<number, Tasma> = new Map();
  private _tekstyliaCache: Map<number, Textile> = new Map();
  private _koloryCache: Map<number, TextileKoloryCecha> = new Map();
  private _zastosowaniaCache: Map<number, BaseCecha> = new Map();
  private _materialyCache: Map<number, BaseCecha> = new Map();
  private _grubosciCache: Map<number, number> = new Map();
  private _gramaturyCache: Map<number, number> = new Map();
  private _maksymalne_predkosci_zadrukuCache: Map<number, number> = new Map();
  private _sztywnosciCache: Map<number, number> = new Map();
  private _metody_zadrukuCache: Map<number, BaseCecha> = new Map();
  private _praniaCache: Map<number, BaseCecha> = new Map();
  private _przeznaczeniaCache: Map<number, BaseCecha> = new Map();
  private _zadrukiCache: Map<number, BaseCecha> = new Map();
  private _strukturyCache: Map<number, BaseCecha> = new Map();

  private _dataFetched = false;

  private key: StateKey<Res> = makeStateKey("textileKey");
  private isAllkey: StateKey<boolean> = makeStateKey("isAllTextileKey");


  private filteredItemsSubject = new BehaviorSubject<Textile[]>([]);
  filteredItems$ = this.filteredItemsSubject.asObservable();

  constructor(private http: HttpClient, private  hostAddress: HostAddressService,
              private state: TransferState) {}

  fetchAllTekstylia(): Observable<void> {
    if (this._dataFetched) {
      return of();
    }
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tekstyliaAll'//, { nazwy: uncachedNazwy }
    ).pipe(
      map(response => {
        this.processResponse(response);
        this._dataFetched = true;
        return;
      })
    );
  }

  fetchTekstylia(ids: number[]): Observable<Textile[]> {
    const uncachedIds = ids.filter(id => ![...this._tekstyliaCache.values()].some(textile => textile.id === id));
    if (uncachedIds.length === 0) {
      const cachedTekstylia = [...this._tekstyliaCache.entries()]
        .filter(([id, _]) => ids.includes(id))
        .map(([_, tekstylia]) => tekstylia);

      return of(cachedTekstylia);
    }

    let params = new HttpParams();
    uncachedIds.forEach(id => {
      params = params.append('ids', id);
    });

    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tekstylia', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  // fetchTasmaByNazwa(nazwa: string): Observable<Textile[]>{
  //   for (let textile of this._tekstyliaCache.values()) {
  //     if (textile.nazwa === nazwa) {
  //       return of([textile]);
  //     }
  //   }
  //
  //   let params = new HttpParams();
  //   params = params.append('nazwa', nazwa);
  //   return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tekstyliaByName', { params }).pipe(
  //       map(response => this.processResponse(response))
  //   );
  // }

  fetchTekstyliaBySlug(slug: string): Observable<Textile[]>{
    for (let textile of this._tekstyliaCache.values()) {
      if (textile.slug === slug) {
        return of([textile]);
      }
    }

    let params = new HttpParams();
    params = params.append('slug', slug);
    return this.http.get<Res>(this.hostAddress.getHostAddress() + 'get/tekstyliaBySlug', { params }).pipe(
      map(response => this.processResponse(response))
    );
  }

  processResponse(response: Res): Textile[] {
    response.kolory.forEach(kolor => {
      if (!this._koloryCache.has(kolor.id)) {
        this._koloryCache.set(kolor.id, kolor);
      }
    });

    response.zastosowania.forEach(zastosowanie => {
      if (!this._zastosowaniaCache.has(zastosowanie.id)) {
        this._zastosowaniaCache.set(zastosowanie.id, zastosowanie);
      }
    });

    response.materialy.forEach(material => {
      if (!this._materialyCache.has(material.id)) {
        this._materialyCache.set(material.id, material);
      }
    });

    response.metody_zadruku.forEach(item => {
      if (!this._metody_zadrukuCache.has(item.id)) {
        this._metody_zadrukuCache.set(item.id, item);
      }
    });

    response.prania.forEach(item => {
      if (!this._praniaCache.has(item.id)) {
        this._praniaCache.set(item.id, item);
      }
    });

    response.przeznaczenia.forEach(item => {
      if (!this._przeznaczeniaCache.has(item.id)) {
        this._przeznaczeniaCache.set(item.id, item);
      }
    });

    response.zadruki.forEach(item => {
      if (!this._zadrukiCache.has(item.id)) {
        this._zadrukiCache.set(item.id, item);
      }
    });

    response.grubosci.forEach(item => {
      if (!this._grubosciCache.has(item)) {
        this._grubosciCache.set(item, item);
      }
    });

    response.gramatury.forEach(item => {
      if (!this._gramaturyCache.has(item)) {
        this._gramaturyCache.set(item, item);
      }
    });

    response.maksymalne_predkosci_zadruku.forEach(item => {
      if (!this._maksymalne_predkosci_zadrukuCache.has(item)) {
        this._maksymalne_predkosci_zadrukuCache.set(item, item);
      }
    });

    response.sztywnosci.forEach(item => {
      if (!this._sztywnosciCache.has(item)) {
        this._sztywnosciCache.set(item, item);
      }
    });

    response.struktury.forEach(item => {
      if (!this._strukturyCache.has(item.id)) {
        this._strukturyCache.set(item.id, item);
      }
    });

    const tekstylia: Textile[] = response.tekstylia.map(textileRes => {
      if (!this._tekstyliaCache.has(textileRes.id)) {
        const textile: Textile = {
          ...textileRes,
          kolory: textileRes.kolory.map(id => this._koloryCache.get(id)!),
          zastosowania: textileRes.zastosowania.map(id => this._zastosowaniaCache.get(id)!),
          rodzaj_materialu: this._materialyCache.get(textileRes.rodzaj_materialu)!,
          struktura: this._strukturyCache.get(textileRes.struktura)!,
          metody_zadruku: textileRes.metody_zadruku.map(id => this._metody_zadrukuCache.get(id)!),
          pranie: textileRes.pranie.map(id => this._praniaCache.get(id)!),
          przeznaczenia: textileRes.przeznaczenia.map(id => this._przeznaczeniaCache.get(id)!),
          zadruk: this._zadrukiCache.get(textileRes.zadruk)!,
        };
        this._tekstyliaCache.set(textileRes.id, textile);
        return textile;
      }
      return this._tekstyliaCache.get(textileRes.id)!;
    });
    return tekstylia;
  }

  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //     console.error(error); // log to console instead
  //     return of(result as T);
  //   };
  // }

  setState(tekstylia: Res) {
    this.state.set(this.key, tekstylia);
  }
  setStateIsAll() {
    // console.log(tasmy);
    this.state.set(this.isAllkey, true);
  }

  loadState(){
    const res = this.state.get(this.key, null);
    if (res) {
      this.processResponse(res);
    }
    this._dataFetched = this.state.get(this.isAllkey, false);
  }

  updateFilteredItems(newItems: Textile[]): void {
    this.filteredItemsSubject.next(newItems);
  }

  // Ewentualnie dodatkowa metoda pobierająca aktualną wartość
  getFilteredItems(): Textile[] {
    return this.filteredItemsSubject.getValue();
  }
}
