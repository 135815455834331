import { Component } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {NgForOf} from '@angular/common';
import {UnwindDescriptionComponent} from '../../components/unwind-description/unwind-description.component';

@Component({
  selector: 'app-contact-page',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgForOf,
    UnwindDescriptionComponent
  ],
  templateUrl: './contact-page.component.html',
  standalone: true,
  styleUrl: './contact-page.component.css'
})
export class ContactPageComponent {
  form: FormGroup;
  submitted = false;

  FAQ_items = [{title: 'Czym jest druk termotransferowy?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jakie są zalety druku termotransferowego?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jak dobrać odpowiedni materiał do druku?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Czy mogę zamówić próbki materiałów?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jak długo trwa realizacja zamówienia?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Ile to kosztuje?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'}
  ];
  FAQs = [false, false, false, false, false, false];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      imie: [''],
      firma: [''],
      mail: [''],
      tel: [''],
      wiadomosc: [''],
      consent: [false, Validators.requiredTrue],
    });
  }
  async   onSubmit() {
    this.submitted = true; // Mark form as submitted

    if (this.form.valid) {
      try {
        this.form.reset();
        this.submitted = false; // Mark form as submitted

        // Show a toast notification
        // this.toastr.success('Form has been sent!', 'Success');
      } catch (error) {
        console.error('Error saving form data:', error);
      }
    } else {
      // console.error('Form is invalid');
      // this.toastr.error('Please accept our privacy policy', 'Lack of consent');
    }
  }

  untoggleFAQs(isActive: boolean, index: number){
    this.FAQs = this.FAQs.map(() => false);
    this.FAQs[index] = !isActive;
  }
}
