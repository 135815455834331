import { Component } from '@angular/core';

@Component({
  selector: 'app-drop-down-knowledge',
  imports: [],
  templateUrl: './drop-down-knowledge.component.html',
  standalone: true,
  styleUrl: './drop-down-knowledge.component.css'
})
export class DropDownKnowledgeComponent {

}
