<div class="productCard">
  <div class="title-picture">
    <div class="pictuceContainer">
      <div class="picture">
        <img [ngSrc]="'assets/drukarki/'+produkt.slug+'/01.webp'" fill="true" alt="" class="image">
      </div>
    </div>
    <div class="title-colorSwatch">
      <div class="title">{{produkt.model.nazwa}}</div>
      <div class="propertyList">
        <div class="line"></div>
        <div class="properties">
          <div class="name">Głowica</div>
<!--          <div class="subProperties">{{concatenateNazwa(produkt.glowice)}}</div>-->
        </div>
        <div class="line"></div>
        <div class="properties">
          <div class="name">Zastosowania</div>
<!--          <div class="subProperties">{{concatenateNazwa(produkt.zastosowania)}}</div>-->
        </div>
        <div class="line"></div>
        <div class="properties">
          <div class="name">Materiały</div>
<!--          <div class="subProperties">{{concatenateNazwa(produkt.materialy)}}</div>-->
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="productCardArrow">
      <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_2209_1362)">
          <path d="M41.7257 27.6624L30.4757 38.9124C30.298 39.078 30.063 39.1682 29.8201 39.1639C29.5772 39.1596 29.3455 39.0612 29.1737 38.8895C29.0019 38.7177 28.9035 38.486 28.8993 38.2431C28.895 38.0002 28.9851 37.7651 29.1507 37.5874L38.7992 27.9374H13.5632C13.3146 27.9374 13.0761 27.8387 12.9003 27.6628C12.7245 27.487 12.6257 27.2486 12.6257 26.9999C12.6257 26.7513 12.7245 26.5128 12.9003 26.337C13.0761 26.1612 13.3146 26.0624 13.5632 26.0624H38.7992L29.1507 16.4124C28.9851 16.2347 28.895 15.9997 28.8993 15.7568C28.9035 15.5139 29.0019 15.2822 29.1737 15.1104C29.3455 14.9386 29.5772 14.8402 29.8201 14.836C30.063 14.8317 30.298 14.9218 30.4757 15.0874L41.7257 26.3374C41.9013 26.5132 41.9999 26.7515 41.9999 26.9999C41.9999 27.2484 41.9013 27.4866 41.7257 27.6624Z" fill="#D12A35"/>
        </g>
        <defs>
          <filter id="filter0_i_2209_1362" x="12.6257" y="14.8358" width="30.3743" height="25.3282" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="1"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2209_1362"/>
          </filter>
        </defs>
      </svg>
    </div>
  </div>
</div>
