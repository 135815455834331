import {Component, ElementRef, ViewChild} from '@angular/core';
import {NgForOf, NgOptimizedImage} from '@angular/common';
import {UnwindDescriptionComponent} from '../../components/unwind-description/unwind-description.component';

@Component({
  selector: 'app-home-page',
  imports: [
    NgOptimizedImage,
    UnwindDescriptionComponent,
    NgForOf
  ],
  templateUrl: './home-page.component.html',
  standalone: true,
  styleUrl: './home-page.component.css'
})
export class HomePageComponent {
  category_items = [{category: 'Taśmy termotransferowe', description: 'Sprawdź nasz asortyment taśm termotransferowych zapewniających najwyższą jakość nadruku i trwałość oznakowania niezależnie od warunków.', img: 'assets/termotransfer.jpg', linkText: 'Zobacz taśmy termotransferowe', href: '/tasmy-termotransferowe'},
    {category: 'Taśmy tekstylne', description: 'Nylonowe, satynowe, poliuretanowe i bawełniane.  Oferujemy szeroki wybór trwałych i estetycznych materiałów, które sprostają wymaganiom każdej marki.', img: 'assets/tekstylia.jpg', linkText: 'Zobacz taśmy tekstylne', href: '/tekstylia'},
    {category: 'Etykiety', description: 'Profesjonalne etykiety to podstawa skutecznej identyfikacji. Odkryj sprawdzone i niezawodne rozwiązania dostosowane do specyfiki Twojej branży.', img: 'assets/etykiety.jpg', linkText: 'Zobacz etykiety', href: '/etykiety'},
    {category: 'Drukarki', description: 'Zobacz naszą selekcję sprawdzonych drukarek, które pozwolą Ci osiągnąć najwyższą jakość oznakowania przy zachowaniu niskich kosztów eksploatacji.', img:'assets/drukarki.jpg', linkText: 'Zobacz drukarki', href: '/drukarki'},
    {category: 'Nawijaki', description: 'Poznaj wysokiej jakości nawijaki etykiet, które pomogą Ci zoptymalizować proces przygotowania materiałów do aplikacji i zwiększyć efektywność produkcji.', img: 'assets/nawijaki.jpg', linkText: 'Zobacz nawijaki', href: '/nawijaki'},
    {category: 'Aplikatory', description: 'Sprawdź nasze aplikatory, dzięki którym szybko  i dokładnie naniesiesz etykiety samoprzylepne przy zachowaniu powtarzalnej jakości.', img: 'assets/aplikatory.jpg', linkText: 'Zobacz aplikatory', href: '/aplikatory'}]
  active_category_idx = 0;

  abouts_items = [{title: 'Nowoczesny park maszynowy', description: 'Jako nieliczni w Polsce dysponujemy zaawansowanym zapleczem technologicznym do konfekcjonowania taśm termotransferowych i materiałów tekstylnych, pozwalającym sprostać najbardziej wymagającym oczekiwaniom.'},
    {title: 'Bogata i zróżnicowana oferta', description: 'Jako nieliczni w Polsce dysponujemy zaawansowanym zapleczem technologicznym do konfekcjonowania taśm termotransferowych i materiałów tekstylnych, pozwalającym sprostać najbardziej wymagającym oczekiwaniom.'},
    {title: 'Indywidualne rozwiązania', description: 'Jako nieliczni w Polsce dysponujemy zaawansowanym zapleczem technologicznym do konfekcjonowania taśm termotransferowych i materiałów tekstylnych, pozwalającym sprostać najbardziej wymagającym oczekiwaniom.'},
    {title: 'Kompleksowa obsługa', description: 'Jako nieliczni w Polsce dysponujemy zaawansowanym zapleczem technologicznym do konfekcjonowania taśm termotransferowych i materiałów tekstylnych, pozwalającym sprostać najbardziej wymagającym oczekiwaniom.'}
  ];
  abouts = [false, false, false, false];

  FAQ_items = [{title: 'Czym jest druk termotransferowy?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jakie są zalety druku termotransferowego?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jak dobrać odpowiedni materiał do druku?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Czy mogę zamówić próbki materiałów?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Jak długo trwa realizacja zamówienia?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'},
    {title: 'Ile to kosztuje?', description: 'Druk termotransferowy to technologia polegająca na nanoszeniu obrazu na różne materiały za pomocą taśmy barwiącej i podgrzewanej głowicy drukarki. Jest stosowany w produkcji etykiet, wszywek, opakowań, metek czy wstążek, znajdując zastosowanie w różnorodnych branżach. Dzięki wysokiej trwałości świetnie sprawdza się tam, gdzie wymagana jest odporność na ścieranie, wilgoć czy wysokie temperatury.'}
  ];
  FAQs = [false, false, false, false, false, false];

  untoggleAbouts(isActive: boolean, index: number){
    this.abouts = this.abouts.map(() => false);
    this.abouts[index] = !isActive;
  }

  untoggleFAQs(isActive: boolean, index: number){
    this.FAQs = this.FAQs.map(() => false);
    this.FAQs[index] = !isActive;
  }
  scrollToComponent() {
    document.getElementById("targetElement")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
