import {Component, Input, OnInit} from '@angular/core';
import {Textile, TextileKoloryCecha} from '../../services/textile-data/textile-data.service';
import {NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-textile-card',
  imports: [
    NgOptimizedImage,
    NgForOf,
    NgIf,
    RouterLink,
  ],
  templateUrl: './textile-card.component.html',
  standalone: true,
  styleUrl: './textile-card.component.css'
})
export class TextileCardComponent implements OnInit {

  @Input() produkt: Textile = {
    id: 0,
    nazwa: '',
    nazwa_long: '',
    kolory: [],
    rodzaj_materialu: {
      id: 0,
      nazwa: ''
    },
    struktura: {
      id: 0,
      nazwa: ''
    },
    grubosc: 0,
    gramatura: 0,
    optymalna_predkosc_zadruku: 0,
    maksymalna_predkosc_zadruku: 0,
    predkosc_zadruku_skala: 0,
    sztywnosc: 0,
    odpornosc_na_pranie: 0,
    material_niestrzepiacy: false,
    metody_zadruku: [],
    zadruk: {
      id: 0,
      nazwa: ''
    },
    charakterystyka: '',
    opis: '',
    pranie: [],
    prasowanie: 0,
    przeznaczenia: [],
    zastosowania: [],
    polecane_tasmy: [],
    slug: '',
    tasmy: [],
    meta: ''
  }

  activePic = '';
  activeColor = 0;

  concatenateNazwa(objects: { nazwa: string }[]): string {
    return objects.map(obj => obj.nazwa).join(', ');
  }

  changeActive(color: TextileKoloryCecha){
    this.activePic = 'assets/tekstylia/'+this.produkt.nazwa + '/' + encodeURIComponent(color.nazwa) + '-1920.webp';
    this.activeColor = color.id;
  }

  ngOnInit(): void {
    this.activePic = 'assets/tekstylia/'+this.produkt.nazwa + '/' + encodeURIComponent(this.produkt.kolory[0].nazwa) + '-1920.webp';
    this.activeColor = this.produkt.kolory[0].id;
  }

  protected readonly encodeURIComponent = encodeURIComponent;

}
