import {Component, Input} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {Aplikator, SubAplikator} from '../../services/aplikatory-data/aplikatory-data.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-aplikator-card',
  imports: [
    NgForOf,
    NgOptimizedImage,
    RouterLink,
    NgIf
  ],
  templateUrl: './aplikator-card.component.html',
  standalone: true,
  styleUrl: './aplikator-card.component.css',
  animations: [
    trigger('fadeAnimation', [
      state('true', style({opacity: 1})),
      state('false', style({opacity: 1})),
      transition('false => true', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ]),
      transition('true => false', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 }))
      ])
    ]),
    trigger('slowFadeAnimation', [
      state('true', style({opacity: 1})),
      state('false', style({opacity: 1})),
      transition('false => true', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('600ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition('true => false', [ // Animacja przy każdej zmianie wartości
        style({ opacity: 0 }),
        animate('600ms ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class AplikatorCardComponent {
  @Input() produkt: Aplikator = {
    id: 0,
    slug: '',
    meta: '',
    opis: '',
    details: []
  }

  active_variant = 0;

  animationStateTitle: boolean = false;
}
