import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ExtendedCecha} from "../../services/tasma-data/tasma-data.service";

@Component({
  selector: 'app-color-drawer',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './color-drawer.component.html',
  styleUrl: './color-drawer.component.css'
})
export class ColorDrawerComponent {
  @Input() kolor: ExtendedCecha = {
    id: 0,
    nazwa: '',
    typ: 0,
    wartosc: '#FFFFFF'
  }

  getGradient() {
    // Modify the color as needed to create a gradient effect
    let lighterColor = '#FFFFFF'
    if (this.kolor.typ == 1) {
      lighterColor = this.lightenColor(this.kolor.wartosc, 30); // Use a function to lighten the color
    } else if (this.kolor.typ == 2) {
      lighterColor = this.lightenColor(this.kolor.wartosc, 60); // Use a function to lighten the color
    }
    return `linear-gradient(-45deg, ${this.kolor.wartosc}, ${lighterColor}, ${this.kolor.wartosc})`;
  }

  lightenColor(color: string, percent: number): string {
    let num = parseInt(color.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = (num >> 8 & 0x00FF) + amt,
      G = (num & 0x0000FF) + amt;

    return '#' + (0x1000000 + (R < 255 ? R : 255) * 0x10000 + (B < 255 ? B : 255) * 0x100 + (G < 255 ? G : 255)).toString(16).slice(1);
  }

  // darkenColor(color: string, percent: number): string {
  //   return this.lightenColor(color, -percent);
  // }
}
